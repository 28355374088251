// @ts-strict-ignore
import { useState } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';
import { trackSendSmsAnalyticsEvent } from 'analytics/events/send-sms';

import { observer } from 'mobx-react';

import { PatientCommunicationFetcher } from 'fetchers/PatientCommunicationFetcher';

import { showToast } from 'utils/UserMessageUtils';

import { useDoctorsAndAssignees } from 'hooks/useDoctor';

import { useTicketAssign } from 'hooks/useTicketAssign';

import { useToggle } from 'hooks/useToggle';

import SendSmsPersonalRequest from 'views/Modals/SendSmsPersonalRequest';

import SmsBlockedPopup from 'views/Modals/SmsBlockedPopup';

import { usePatientModel } from 'components/Patient/usePatientModel';

import TicketReassignAction from 'components/Ticket/TicketRow/TicketReassignAction';
import { TicketActionsProps } from 'components/Ticket/TicketsContainers/TicketOverviewProvider';
import { useTicketActionCallbacks } from 'components/Ticket/TicketsContainers/useTicketActionCallbacks';

import { OutlinedButton } from 'components/UIkit/atoms/Button';

import TicketActionsMenu from './TicketActionsMenu';

const TicketActions = ({ ticket, ticketIndex, ticketSectionCurrentPage }: TicketActionsProps) => {
  const assignTicket = useTicketAssign();
  const { onReopenTask } = useTicketActionCallbacks();

  const { mainAssign, isCurrentDoctorAssigned, currentDoctor, ticketDoctor, handleDoctorSelected } =
    useDoctorsAndAssignees(ticket);
  const patient = usePatientModel(ticket.patientId);
  const { id: doctorId } = currentDoctor;
  const [patientSmsMessage, setPatientSmsMessage] = useState('');
  const [isPatientSmsInProgress, setIsPatientSmsInProgress] = useState(false);
  const sendPatientSmsModal = useToggle(false);
  const smsBlockedPopup = useToggle(false);

  const handleReopen = () => {
    trackActionButtonAnalyticsEvent({
      action: AnalyticEventAction.Reopen,
      patient_id: ticket.patientId,
      ticket_id: ticket.id,
      item_index: ticketIndex + 1,
      page_number: ticketSectionCurrentPage + 1
    });
    onReopenTask(ticket);
  };

  const handlePickUp = async () => {
    trackActionButtonAnalyticsEvent({
      action: AnalyticEventAction.PickUp,
      patient_id: ticket.patientId,
      ticket_id: ticket.id,
      item_index: ticketIndex + 1,
      page_number: ticketSectionCurrentPage + 1
    });
    await assignTicket(ticket, doctorId);
  };

  const handleSendPatientSms = async () => {
    trackSendSmsAnalyticsEvent({
      action: AnalyticEventAction.Send
    });

    setIsPatientSmsInProgress(true);
    const contactId = ticket.operatorTicket?.patientContactId;

    try {
      await PatientCommunicationFetcher.sendSmsToPatient(patient.id, {
        message: patientSmsMessage,
        ...(contactId && {
          contactId
        })
      });

      let message = `Message Sent to ${patient.fullName}`;

      if (contactId) {
        const contact = patient.getContactById(contactId);
        message = `Message Sent to ${contact.name} (${contact.relationship})`;
      }

      showToast({
        message
      });
    } finally {
      setIsPatientSmsInProgress(false);
      sendPatientSmsModal.setIsOpen(false);
      setPatientSmsMessage('');
    }
  };

  const onSmsCancelClicked = () => {
    trackSendSmsAnalyticsEvent({
      action: AnalyticEventAction.Cancel
    });

    sendPatientSmsModal.toggle();
  };

  const handleSendPatientSmsActionClicked = (shouldOpenSmsBlockedPopup: boolean) => {
    if (shouldOpenSmsBlockedPopup) {
      trackSendSmsAnalyticsEvent({
        action: AnalyticEventAction.Send,
        value: 'blocked'
      });
      smsBlockedPopup.toggle();
    } else {
      sendPatientSmsModal.toggle();
    }
  };

  if (ticket.isClosedOrResolved) {
    return (
      ticket.isTask &&
      onReopenTask && <OutlinedButton onClick={handleReopen}>Reopen</OutlinedButton>
    );
  }

  if (!mainAssign) {
    if (ticket.isTask) {
      return (
        <TicketActionsMenu
          ticket={ticket}
          ticketIndex={ticketIndex}
          ticketSectionCurrentPage={ticketSectionCurrentPage}
        />
      );
    } else {
      return <OutlinedButton onClick={handlePickUp}>Pick Up</OutlinedButton>;
    }
  }

  return (
    <>
      <SendSmsPersonalRequest
        onSendClicked={handleSendPatientSms}
        onCancelClicked={onSmsCancelClicked}
        onMessageChanged={setPatientSmsMessage}
        message={patientSmsMessage}
        isOpen={sendPatientSmsModal.isOpen}
        isSending={isPatientSmsInProgress}
        title={`SMS to ${patient?.fullName}`}
        helperText="This is a one-way SMS. Patient will not be able to reply to this message."
      />
      <SmsBlockedPopup isOpen={smsBlockedPopup.isOpen} onCancelClicked={smsBlockedPopup.toggle} />
      {isCurrentDoctorAssigned ? (
        <TicketActionsMenu
          ticket={ticket}
          ticketIndex={ticketIndex}
          ticketSectionCurrentPage={ticketSectionCurrentPage}
          onSendPatientSmsClicked={handleSendPatientSmsActionClicked}
        />
      ) : (
        <TicketReassignAction
          mainAssign={mainAssign}
          ticketDoctor={ticketDoctor}
          handleDoctorSelected={handleDoctorSelected}
          ticketId={ticket.id}
          testHook={`ticketReassignAction_${ticket.id}`}
          ticketIndex={ticketIndex}
          ticketSectionCurrentPage={ticketSectionCurrentPage}
        />
      )}
    </>
  );
};

export default observer(TicketActions);
