// @ts-strict-ignore
import { ChangeEvent, FC, Fragment } from 'react';

import { Box, Grid } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { AnalyticEventAction } from 'analytics';

import { getEventActionOnTextChange } from 'analytics/events/input-usage';
import {
  PathwayAnswerAlertLevel,
  trackPathwayAnswerAnalyticsEvent
} from 'analytics/events/pathway-answer';

import { castArray, get, isArray } from 'lodash/fp';

import { observer } from 'mobx-react';

import moment from 'moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import { useStores } from 'mobx/hooks/useStores';

import { pluralize } from 'utils/StringUtils';

import { FeatureIntroCodes } from 'models/Doctor';
import {
  BaseQuestion,
  LinkedPathwayUrgency,
  PathwayBasicInfo,
  PathwayOptionOutcome,
  PathwayQuestionTypes,
  PathwaySelectionAnswer,
  PathwayTemplateQuestion,
  PathwayTextArrayAnswer
} from 'models/PathwayTemplates';

import StyledInput from 'views/Widgets/StyledInput';

import { StyledTagInput } from 'views/Widgets/StyledSelect';

import Icon from 'components/Icons/Icon';
import { HtmlContent } from 'components/Ticket/TicketRow/HtmlContent';
import { Tooltip } from 'components/Tooltip';
import { FilledButton, LinkButton } from 'components/UIkit/atoms/Button';
import { LabeledCheckbox } from 'components/UIkit/atoms/Checkbox/LabeledCheckbox';
import { RadioButton } from 'components/UIkit/atoms/RadioButton/RadioButton';
import { Text } from 'components/UIkit/atoms/Text';

import './PathwayQuestionView.scss';

interface Props {
  question: PathwayTemplateQuestion;
  hasKeyQuestionsFilter: boolean;
  linkToPathway: (selectedPathway: PathwayBasicInfo) => void;
  shouldShowScore?: boolean;
}

const SHOW_WARNING_THRESHOLD = PathwayOptionOutcome.CLINIC;

export const IMMEDIATE_ATTENTION = 'IMMEDIATE ATTENTION';
export const ATTENTION_TODAY = 'ATTENTION TODAY';
export const NO_WARNING = 'NO WARNING';

const PathwayQuestionView: FC<Props> = ({
  question,
  linkToPathway,
  hasKeyQuestionsFilter,
  shouldShowScore = true
}) => {
  const { pathwaysStore, userStore, ticketsStore, callLoggingStore } = useStores();
  const handleOptionClicked = (answer: PathwaySelectionAnswer) => {
    pathwaysStore.answerQuestion(question.id, answer);
  };

  const handleTextAnswerChanged = (e: ChangeEvent<HTMLInputElement>) => {
    pathwaysStore.answerQuestion(question.id, e.target.value);
  };

  const renderQuestionOptions = (questionDetails: BaseQuestion) => {
    const callPathwayAnswer = pathwaysStore.getAnswerForQuestion(
      pathwaysStore.currentPathwayInfo.id,
      question.id
    );

    switch (questionDetails.type) {
      case PathwayQuestionTypes.MULTIPLE:
      case PathwayQuestionTypes.SINGLE:
        const baseQuestion = pathwaysStore.getBaseQuestionById(question.id);

        return baseQuestion.optionIds.map((optionId) => {
          const option = pathwaysStore.pathwaysData.options[optionId];
          const score = get(['outcomes', option.id, 'score'], question);
          const outcome = get(['outcomes', option.id, 'outcome'], question);
          const optionLinkedPathway = get(['outcomes', option.id, 'linkedPathway'], question);
          const isMultiple = questionDetails.type === PathwayQuestionTypes.MULTIPLE;
          const linkedPathway = pathwaysStore.getPathwayBasicInfo(optionLinkedPathway?.id);
          const isHighUrgency = optionLinkedPathway?.urgency === LinkedPathwayUrgency.High;
          let isSelected = false;

          if (callPathwayAnswer) {
            const values = castArray(callPathwayAnswer.value);
            isSelected = values.some(
              (answeredOption: PathwaySelectionAnswer) => answeredOption.optionId === optionId
            );
          }

          const label = (
            <Box>
              <HtmlContent>{option.title}</HtmlContent>
              {Number.isInteger(score) && shouldShowScore
                ? `(${Math.abs(score)} ${pluralize('point', score)})`
                : null}{' '}
            </Box>
          );

          const optionClicked = () => {
            handleOptionClicked({
              optionId: option.id,
              title: option.title,
              score,
              outcome,
              action: option.action
            });
            const outcomeAfterAnswer =
              pathwaysStore.getOutcomeForQuestionOnCurrentPathway(question);
            let alertLevel: PathwayAnswerAlertLevel = NO_WARNING;
            if (outcomeAfterAnswer === PathwayOptionOutcome.CLINIC) {
              alertLevel = ATTENTION_TODAY;
            }
            if (outcomeAfterAnswer === PathwayOptionOutcome.CLINIC_EMERGENT) {
              alertLevel = IMMEDIATE_ATTENTION;
            }

            trackPathwayAnswerAnalyticsEvent({
              action: isSelected ? AnalyticEventAction.Remove : AnalyticEventAction.Add,
              type: isMultiple ? 'multiselection' : 'radio',
              alert_level: alertLevel,
              virtual_page: 'pathway view',
              tab: hasKeyQuestionsFilter ? 'Key Questions' : 'All Questions',
              value: questionDetails.title
            });
          };

          return (
            <Fragment key={optionId}>
              <div className="select-container">
                {isMultiple ? (
                  <LabeledCheckbox
                    id={optionId}
                    onChange={optionClicked}
                    checked={isSelected}
                    label={label}
                    testHook={optionId}
                  />
                ) : (
                  <Tooltip
                    label={
                      <RadioButton
                        id={optionId}
                        checked={isSelected}
                        label={label}
                        onChange={optionClicked}
                        testHook={optionId}
                        canToggle
                      />
                    }
                    disabled={
                      userStore.currentDoctor.hasFeature(FeatureIntroCodes.ToggleRadioButton) ||
                      pathwaysStore.currentPathwayAnsweredQuestions.filter(
                        (answer) => answer.questionType === PathwayQuestionTypes.SINGLE
                      ).length > 1
                    }
                    placement="right"
                    controller={{
                      visible: isSelected,
                      onClickOutside: () =>
                        userStore.updateIntroFinished(FeatureIntroCodes.ToggleRadioButton)
                    }}
                    maxWidth={334}
                    offset={[0, 40]}
                  >
                    <Box p={20} display="flex" flexDirection="column">
                      <Text mb={8}>NEW!</Text>

                      <Text variant="body2" mb={20}>
                        You can now clear a response by clicking the circle again
                      </Text>

                      <Box alignSelf="flex-end">
                        <FilledButton
                          onClick={() =>
                            userStore.updateIntroFinished(FeatureIntroCodes.ToggleRadioButton)
                          }
                        >
                          Got It!
                        </FilledButton>
                      </Box>
                    </Box>
                  </Tooltip>
                )}
              </div>

              {isSelected && Boolean(linkedPathway) && (
                <Grid container gap={8} mt={8} alignItems="center">
                  <StyledLinkToArrowIcon />

                  <StyledBox isHighUrgency={isHighUrgency}>
                    <LinkButton
                      onClick={() => linkToPathway(linkedPathway)}
                      size="large"
                      variant="secondary"
                    >
                      {isHighUrgency
                        ? `Continue in ${linkedPathway.name} Pathway`
                        : `Consider ${linkedPathway.name} Pathway`}
                    </LinkButton>
                  </StyledBox>
                </Grid>
              )}
            </Fragment>
          );
        });

      case PathwayQuestionTypes.DATE:
        const handleDateChanged = (date: Date) => {
          if (date && moment(date).isValid()) {
            const answerForQuestion = pathwaysStore.getAnswerForQuestion(
              pathwaysStore.currentPathwayInfo.id,
              question.id
            );
            pathwaysStore.answerQuestion(question.id, date.toISOString());

            trackPathwayAnswerAnalyticsEvent({
              action: answerForQuestion ? AnalyticEventAction.Update : AnalyticEventAction.Add,
              type: 'date',
              alert_level: 'NO WARNING',
              virtual_page: 'pathway view',
              tab: hasKeyQuestionsFilter ? 'Key Questions' : 'All Questions',
              value: questionDetails.title
            });
          } else {
            pathwaysStore.answerQuestion(question.id, null);

            trackPathwayAnswerAnalyticsEvent({
              action: AnalyticEventAction.Remove,
              type: 'date',
              alert_level: 'NO WARNING',
              virtual_page: 'pathway view',
              tab: hasKeyQuestionsFilter ? 'Key Questions' : 'All Questions',
              value: questionDetails.title
            });
          }
        };

        return (
          <div className="answer-container">
            <DateTimePicker
              onChange={handleDateChanged}
              step={1}
              date
              time={false}
              value={callPathwayAnswer ? moment(callPathwayAnswer.value).toDate() : null}
              format="MM/DD/YYYY"
              containerClassName="date-answer"
            />
          </div>
        );
      case PathwayQuestionTypes.TEXT:
        return (
          <div className="text-answer">
            <StyledInput
              type="textarea"
              placeholder="Type response here..."
              onChange={handleTextAnswerChanged}
              onBlur={(e, currentValue, valueAfterFocus) => {
                const action = getEventActionOnTextChange(valueAfterFocus, currentValue);

                trackPathwayAnswerAnalyticsEvent({
                  action,
                  type: 'open text',
                  alert_level: 'NO WARNING',
                  virtual_page: 'pathway view',
                  tab: hasKeyQuestionsFilter ? 'Key Questions' : 'All Questions',
                  value: questionDetails.title
                });
              }}
              value={callPathwayAnswer ? callPathwayAnswer.value : ''}
              onFocus={(event) => event.preventDefault()}
            />
          </div>
        );
      case PathwayQuestionTypes.TEXT_ARRAY:
        const handleTextArrayAnswerChanged = (value: PathwayTextArrayAnswer[]) => {
          const action =
            callPathwayAnswer?.value.length > value.length
              ? AnalyticEventAction.Remove
              : AnalyticEventAction.Add;

          trackPathwayAnswerAnalyticsEvent({
            action,
            type: 'text array',
            alert_level: 'NO WARNING',
            virtual_page: 'pathway view',
            tab: hasKeyQuestionsFilter ? 'Key Questions' : 'All Questions',
            value: questionDetails.title
          });

          pathwaysStore.answerQuestion(
            question.id,
            value?.map(({ label, ...rest }) => ({ label, title: label, ...rest }))
          );
        };
        const withDx = questionDetails.withDxAlert;
        let value = [];
        if (callPathwayAnswer) {
          if (!isArray(callPathwayAnswer.value)) {
            /* Fixes a bug where we had a question defined as text changed to textArray making all the drafts saved in the original type crash,
            remove when either no more drafts with Cancer diagnosis type text or the
            pathway's inherent infrastructure issue (drafts can be out of sync with pathway data) is solved @yuvalSela 22.9.2022 */
            value = [
              {
                label: callPathwayAnswer.value,
                title: callPathwayAnswer.value,
                value: callPathwayAnswer.value
              }
            ];
          } else {
            value = callPathwayAnswer.value;
          }
        }
        return (
          <div className="text-array-container">
            <StyledTagInput
              onFocus={(event) => event.preventDefault()}
              className="text-array-answer"
              placeholder="Type response here..."
              onChange={handleTextArrayAnswerChanged}
              value={value}
              getOptionLabel={(option: any) => option.title}
              formatOptionLabel={(option) => {
                return withDx ? (
                  <span>
                    {option.title}{' '}
                    {Boolean(option.outcome) && <span className="option-alert">• Relevant Dx</span>}
                  </span>
                ) : (
                  option.title
                );
              }}
            />
            {withDx && (
              <span className="dx-disclaimer">
                Alerts based on EMR information seek to surface relevant information faster, but are
                not a substitute for chart review or nursing judgment.
              </span>
            )}
          </div>
        );

      default:
        throw new Error('Unknown question type ' + questionDetails.type);
    }
  };

  const getAlertForQuestion = (question: BaseQuestion, isEmergency: Boolean) => {
    if (question.withDxAlert) {
      const pathwayName = pathwaysStore.currentPathwayInfo.name;

      return (
        <div className="dx-alert">
          <div className="text-uppercase" data-test-hook="dx-alert-title">
            RELEVANT DX WITH ‘{pathwayName}’
          </div>
        </div>
      );
    }

    return isEmergency ? IMMEDIATE_ATTENTION : ATTENTION_TODAY;
  };

  const questionDetails = pathwaysStore.getBaseQuestionById(question.id);
  const questionOutcome = pathwaysStore.getOutcomeForQuestionOnCurrentPathway(question);
  const showWarning =
    questionOutcome >= PathwayOptionOutcome.CLINIC &&
    pathwaysStore.highestPotentialOutcome >= SHOW_WARNING_THRESHOLD;
  const isEmergency = questionOutcome === PathwayOptionOutcome.CLINIC_EMERGENT;
  const callPathwayAnswer = pathwaysStore.getAnswerForQuestion(
    pathwaysStore.currentPathwayInfo.id,
    question.id
  );
  const ticket =
    callLoggingStore.preselectedTicketIds &&
    ticketsStore.ticketsMap.get(callLoggingStore.preselectedTicketIds[0]);
  const reportingDoctor = ticket?.operatorTicket?.reportingDoctor;

  return (
    <div className="question">
      <div className="title">
        <span>{questionDetails.title}</span>
        {showWarning && (
          <div className={`warning-label ${isEmergency ? 'emergency' : 'warning'}`}>
            {getAlertForQuestion(questionDetails, isEmergency)}
          </div>
        )}
      </div>
      {questionDetails.subtitle && <span className="subtitle">{questionDetails.subtitle}</span>}

      {callPathwayAnswer?.isPrefilled && (
        <Text variant="helper-text" color="disabled">
          This question was completed as part of ticket creation{' '}
          {reportingDoctor ? `by ${reportingDoctor.firstName} ${reportingDoctor.lastName}` : ''}
        </Text>
      )}

      {renderQuestionOptions(questionDetails)}
    </div>
  );
};

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isHighUrgency'
})<{ isHighUrgency: boolean }>(
  ({ theme, isHighUrgency }) => css`
    background-color: ${isHighUrgency
      ? theme.palette.error.light
      : theme.palette.primary.contrastText};
    padding: ${theme.spacing(2, 4)};
    line-height: 20px;
    border-radius: ${theme.borderRadius.xSmall};
  `
);

const StyledLinkToArrowIcon = styled(Icon.LinkToArrow)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(28)};
    color: ${theme.palette.text.disabled};
  `
);

export default observer(PathwayQuestionView);
