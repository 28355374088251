import { ReactNode } from 'react';

import { Method } from 'axios';

import { HTTP_METHODS, isCrud } from 'utils/http.utils';

import { HttpError } from './HttpError';
import { ERROR_CODE } from './serverErrorCode';

export enum ErrorName {
  Unauthorized = 'Unauthorized',
  MissingPermission = 'MissingPermission',
  NoNetwork = 'NoNetworkError',
  NoServerResponse = 'NoServerResponse',
  HttpError = 'HttpError',
  RequestCancelled = 'RequestCancelled',
  MissingEntity = 'MissingEntity',
  PatientNoExist = 'PatientNoExist',
  InvalidRequest = 'InvalidRequest', // see EH-3303
  InvalidEmail = 'InvalidEmail',
  InvalidPhone = 'InvalidPhone',
  NotMobilePhone = 'NotMobilePhone',
  PhoneInUse = 'PhoneInUse',
  DuplicationError = 'DuplicationError', // see EH-3303
  MrnInUse = 'MrnInUse',
  OverlappingIntervals = 'OverlappingIntervals',
  IntervalOutOfAllowedScope = 'IntervalOutOfAllowedScope',
  ResolveTicketsConflict = 'ResolveTicketsConflict',
  ResolveClosedTicket = 'ResolveClosedTicket',
  UserMissingData = 'UserMissingData',
  DisableNonEmptyLocation = 'DisableNonEmptyLocation',
  DuplicateEpisodeName = 'DuplicateEpisodeName',
  DuplicateEpisodeEndReason = 'DuplicateEpisodeEndReason',
  InvalidEpisodeDuration = 'InvalidEpisodeDuration',
  EnrollInActiveCm = 'EnrollInActiveCm',
  TicketDoublePickup = 'TicketDoublePickup',
  DuplicateReason = 'DuplicateReason',
  UserBlockSendingSms = 'UserBlockSendingSms',
  FailedSendingCallToEmr = 'FailedSendingCallToEmr',
  FailedToGetHistory = 'FailedToGetHistory',
  AuditFailed = 'AuditFailed',
  CareIntervalFailed = 'CareIntervalFailed',
  UpdateIntroFeatureFailed = 'UpdateIntroFeatureFailed',
  DraftCallActionFailed = 'DraftCallActionFailed',
  ThoughtSpot = 'ThoughtSpot',
  SSOAuthenticatedButNotRegistered = 'SSOAuthenticatedButNotRegistered',
  DuplicateTicketSubTypeOperatorName = 'DuplicateTicketSubTypeOperatorName',
  DuplicateTicketTypeDisplayNameForPatientApp = 'DuplicateTicketTypeDisplayNameForPatientApp',
  TicketConnectedToDraft = 'TicketConnectedToDraft',
  ThoughtSpotNewUser = 'ThoughtSpotNewUser',
  BulkInviteInProgress = 'BulkInviteInProgress',
  SummaryCharacterLimit = 'SummaryCharacterLimit',
  LandPhoneOnAutoProtocol = 'LandPhoneOnAutoProtocol',
  FailedToSendSms = 'FailedToSendSms',
  DuplicateRoleName = 'DuplicateRoleName'
}

interface ErrorSpec {
  name: ErrorName;
  title?: string;
  description?: ReactNode;
}

export function getErrorSpecByServerErrorCode(errorCode: ERROR_CODE): ErrorSpec {
  switch (errorCode) {
    case ERROR_CODE.PATIENT_DOES_NOT_EXIST:
      return { name: ErrorName.PatientNoExist, title: 'Patient Does not Exist' };
    case ERROR_CODE.INVALID_REQUEST:
      return { name: ErrorName.InvalidRequest, title: 'Invalid Request' };
    case ERROR_CODE.INVALID_EMAIL:
      return { name: ErrorName.InvalidEmail, title: 'Invalid email' };
    case ERROR_CODE.INVALID_PHONE:
      return { name: ErrorName.InvalidPhone, title: 'Invalid phone' };
    case ERROR_CODE.NOT_MOBILE_PHONE:
      return { name: ErrorName.NotMobilePhone, title: 'Landline phone number cannot be used' };
    case ERROR_CODE.DUPLICATE_PHONE:
      return { name: ErrorName.PhoneInUse, title: 'Phone Number in Use' };
    case ERROR_CODE.DUPLICATE_MRN:
      return { name: ErrorName.MrnInUse, title: 'Medical Record Number in Use' };
    case ERROR_CODE.RESOLVE_TICKETS_CONFLICT:
      return {
        name: ErrorName.ResolveTicketsConflict,
        title: 'Cannot Resolve Ticket(s)',
        description: `One or more of the tickets you selected could not be resolved, because they are connected to other tickets by a logged call.\nTo proceed, resolve all connected tickets together using the checkmarks in the Patient Page, or disconnect the call from this ticket to resolve it individually.`
      };

    case ERROR_CODE.TICKET_CONNECTED_TO_DRAFT:
      return {
        name: ErrorName.TicketConnectedToDraft,
        title: 'Disconnect Draft Call(s) to Resolve',
        description: `At least one of the tickets you are trying to resolve is connected to a Call Draft. Please submit that call, or resume and delete it, in order to resolve these ticket(s). `
      };
    case ERROR_CODE.RESOLVE_CLOSED_TICKET:
      return {
        name: ErrorName.ResolveClosedTicket,
        title: 'Item(s) Already Resolved',
        description:
          'At least one of these item(s) have already been resolved. The page has been refreshed to reflect the latest changes.'
      };
    case ERROR_CODE.DUPLICATION_ERROR:
      return {
        name: ErrorName.DuplicationError,
        title: 'Phone or email address are already in use'
      };
    case ERROR_CODE.DISABLE_NON_EMPTY_LOCATION:
      return {
        name: ErrorName.DisableNonEmptyLocation,
        title: 'Location in Use',
        description:
          'This location is assigned to at least 1 patient. Please go to Patient List, and filter by this location to determine who it is. Once all assignments have been removed, this location can be hidden.'
      };
    case ERROR_CODE.DUPLICATE_EPISODE_NAME_ERROR_CODE:
      return { name: ErrorName.DuplicateEpisodeName, title: 'This episode name is already taken' };
    case ERROR_CODE.DUPLICATE_EPISODE_END_REASON:
      return { name: ErrorName.DuplicateEpisodeEndReason, title: 'This reason already exists' };
    case ERROR_CODE.INVALID_EPISODE_DURATION_ERROR:
      return {
        name: ErrorName.InvalidEpisodeDuration,
        title: 'Episode duration cannot be lower than the duration of the tasks in this episode'
      };
    case ERROR_CODE.ENROLL_IN_ACTIVE_CM:
      return { name: ErrorName.EnrollInActiveCm };
    case ERROR_CODE.TICKET_DOUBLE_PICKUP:
      return { name: ErrorName.TicketDoublePickup };
    case ERROR_CODE.OVERLAPPING_INTERVALS:
      return { name: ErrorName.OverlappingIntervals };
    case ERROR_CODE.DUPLICATE_REASON:
      return {
        name: ErrorName.DuplicateReason,
        title: 'This reason already exists'
      };
    case ERROR_CODE.INTERVAL_OUT_OF_ALLOWED_SCOPE:
      return {
        name: ErrorName.IntervalOutOfAllowedScope
      };
    case ERROR_CODE.THOUGHT_SPOT:
      return {
        name: ErrorName.ThoughtSpot,
        title: 'Something Went Wrong',
        description:
          'There was an issue loading this content. Please try again in a few minutes. If the issue persists, reach out for support.'
      };
    case ERROR_CODE.SSO_AUTHENTICATED_BUT_NOT_REGISTERED:
      return {
        name: ErrorName.SSOAuthenticatedButNotRegistered,
        title: 'No user found for this email.'
      };

    case ERROR_CODE.DUPLICATE_TICKET_SUB_TYPE_OPERATOR_NAME:
      return {
        name: ErrorName.DuplicateTicketSubTypeOperatorName,
        title: 'Name is already in use.'
      };

    case ERROR_CODE.DUPLICATE_TICKET_TYPE_DISPLAY_NAME_FOR_PATIENT_APP:
      return {
        name: ErrorName.DuplicateTicketTypeDisplayNameForPatientApp,
        title: 'Name is already in use.'
      };

    case ERROR_CODE.THOUGHT_SPOT_NEW_USER:
      return {
        name: ErrorName.ThoughtSpotNewUser,
        title: 'Authentication Issue',
        description:
          'This user was successfully created. However, we have run into an issue authenticating them for access to Practice Analytics.  Please reach out to support@canopycare.us (please include the email of this new user) and we will sort this out as quickly as possible.'
      };

    case ERROR_CODE.BULK_INVITE_IN_PROGRESS:
      return {
        name: ErrorName.BulkInviteInProgress,
        title: 'Invitation In Progress…',
        description:
          'An invitation to CCM or PCM is currently being sent to at least 1 of these patients. Please wait a moment before taking further action on these patient(s).'
      };

    case ERROR_CODE.SUMMARY_CHARACTER_LIMIT:
      return {
        name: ErrorName.SummaryCharacterLimit,
        title: 'Smart Summary is Too Long',
        description:
          'Please edit your Smart Summary, or click the Regenerate Smart Summary button to create a shorter summary.'
      };

    case ERROR_CODE.LAND_PHONE_ON_AUTO_PROTOCOL:
      return {
        name: ErrorName.LandPhoneOnAutoProtocol,
        title: 'Cannot Invite Landline Phone Number',
        description: (
          <span>
            The invite SMS could not be sent, because the patient has a landline number.
            <br />
            <br /> Please edit their phone number in order to invite them to submit reports.
          </span>
        )
      };
    case ERROR_CODE.PATIENT_UNSUBSCRIBED_FROM_SMS:
      return {
        name: ErrorName.UserBlockSendingSms,
        title: 'This Number Has Blocked SMS from Canopy',
        description: (
          <span>
            This occurs when somebody has replied STOP to an SMS message from Canopy in the past.
            <br />
            <br />
            To un-block, this patient / Callback Contact must text YES to the same number they
            previously blocked.
          </span>
        )
      };

    case ERROR_CODE.FAILED_TO_SEND_SMS:
      return {
        name: ErrorName.FailedToSendSms,
        title: 'Failed to send sms to patient'
      };
    case ERROR_CODE.DUPLICATE_ROLE_NAME:
      return {
        name: ErrorName.DuplicateRoleName,
        title: 'Role Name already exists'
      };
    default:
      return { name: ErrorName.HttpError };
  }
}

type ErrorDescriptionExtractor = (error: HttpError) => string;
type ErrorTransformer = (error: HttpError) => HttpError;

export function transformErrorUiProps(
  title?: string,
  description?: string | ErrorDescriptionExtractor
): ErrorTransformer {
  return (error: HttpError) => {
    if (title) {
      error.ui.title = title;
    }
    if (description) {
      error.ui.description = typeof description === 'string' ? description : description(error);
    }
    return error;
  };
}
export function transformErrorName(name: ErrorName): ErrorTransformer {
  return (error: HttpError) => {
    error.name = name;
    return error;
  };
}

// example - Error on entity Ticket (may be overridden on later stages, like HttpError.setHttpError or transformError)
export function getErrorTitle(
  entityName: string,
  method: Method,
  standardCrudMessage = false
): string {
  if (standardCrudMessage && isCrud(method)) {
    return getStandardCrudErrorMessage(method, entityName);
  }
  return getDefaultErrorTitle(entityName);
}

export function getStandardCrudErrorMessage(method: string, entityName: string): string {
  method = method.toUpperCase();

  switch (method) {
    case HTTP_METHODS.GET:
      return `Failed to fetch ${entityName}s`;
    case HTTP_METHODS.POST:
      return `Failed to create ${entityName}`;
    case HTTP_METHODS.PUT:
      return `Failed to update ${entityName}`;
    case HTTP_METHODS.DELETE:
      return `Failed to delete ${entityName}`;
    default:
      return getDefaultErrorTitle(entityName);
  }
}

export function getDefaultErrorTitle(entityName: string): string {
  return `Error on entity: ${entityName}`;
}

export const CLINICIAN_EDIT_CREATE_ERRORS = [
  ErrorName.DuplicationError,
  ErrorName.NotMobilePhone,
  ErrorName.InvalidPhone,
  ErrorName.InvalidEmail
];
