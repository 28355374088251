// @ts-strict-ignore
import { CallResolutionResponse } from 'fetchers/CallsFetcher';

import { parseDate } from 'utils/DateUtils';

import Call, { CallDurationInterval } from 'models/Call';
import CallPathwayAnswer from 'models/CallPathwayAnswer';
import OrderedCallPathwayAnswer from 'models/OrderedCallPathwayAnswer';
import { BaseOption, PathwayQuestionTypes } from 'models/PathwayTemplates';

import { disabledSummaryText } from 'views/Widgets/CallLogging/CallLoggingSummary';

export class CallsParser {
  static parseCall(unparsedCall: any) {
    return new Call({
      id: unparsedCall.id,
      callDuration: unparsedCall.call_duration_seconds,
      documentationDuration: unparsedCall.documentation_duration_seconds,
      doctorId: unparsedCall.doctor_id,
      patientId: unparsedCall.patient_id,
      note: unparsedCall.note,
      lastReportId: unparsedCall.last_report,
      patientInitiated: unparsedCall.patient_initiated,
      createdAt: parseDate(unparsedCall.created_at),
      updatedAt: parseDate(unparsedCall.updated_at),
      durationIntervals: unparsedCall.call_intervals?.map(
        (interval: any) =>
          new CallDurationInterval(
            parseDate(interval.started_at),
            parseDate(interval.ended_at),
            interval.id,
            interval.type.toLowerCase(),
            interval.doctor_details,
            interval.uuid,
            interval.is_call_attempt
          )
      ),
      auditTrail: unparsedCall.audit_trail,
      callReasons: unparsedCall.call_reasons,
      topicsDiscussed: unparsedCall.topics_discussed ? unparsedCall.topics_discussed : [],
      info: unparsedCall.info,
      orderedPathwayAnswers: unparsedCall.pathwayAnswers
        ? unparsedCall.pathwayAnswers.map((unparsedPathwayAnswer: any) =>
            CallsParser.parseCallPathwayAnswer(unparsedPathwayAnswer)
          )
        : [],
      isDraft: unparsedCall.is_draft,
      ticketIds: new Set<number>(
        unparsedCall.ticketsToCalls?.map((ticketToCall: any) => ticketToCall.ticket_id)
      ),
      documentId: unparsedCall.document_id,

      summary:
        unparsedCall.include_summary_in_emr_note === 'no' ||
        unparsedCall.include_summary_in_emr_note === 'dontOfferAgain'
          ? disabledSummaryText
          : unparsedCall.summary || '',
      includeSummaryInEmrNote: unparsedCall.include_summary_in_emr_note || '',
      smartSummaryId: unparsedCall.summary_id || null,
      isSummaryManuallyEdited: unparsedCall.is_summary_manually_edited || false,
      clinician: unparsedCall.clinician
    });
  }

  static parseCallPathwayAnswer(unparsedPathwayAnswer: {
    id: string;
    question_type: PathwayQuestionTypes;
    pathway_id: string;
    pathway_name: string;
    question_id: string;
    question_title: string;
    category_id: string;
    value: any;
    order: number;
    deniedOptions?: BaseOption[];
  }): CallPathwayAnswer {
    return new OrderedCallPathwayAnswer(
      new CallPathwayAnswer(
        unparsedPathwayAnswer.pathway_id,
        unparsedPathwayAnswer.pathway_name,
        unparsedPathwayAnswer.question_id,
        unparsedPathwayAnswer.question_title,
        unparsedPathwayAnswer.question_type,
        unparsedPathwayAnswer.category_id,
        unparsedPathwayAnswer.value,
        unparsedPathwayAnswer.question_type === PathwayQuestionTypes.MULTIPLE
          ? unparsedPathwayAnswer.value.find((answer: any) => Boolean(answer.deniedOptions))
              ?.deniedOptions
          : null
      ),
      unparsedPathwayAnswer.order
    );
  }

  static parseSaveCallResponse(response: CallResolutionResponse) {
    response.call = CallsParser.parseCall(response.call);
    return response;
  }
}
