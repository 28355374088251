import {
  ChangeEvent,
  FC,
  FocusEventHandler,
  FocusEvent,
  useState,
  ReactNode,
  PropsWithChildren
} from 'react';

import { UseFormGetValues, Validate } from 'react-hook-form';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { Input } from 'reactstrap';
import { InputType } from 'reactstrap/lib/Input';

import { Testable } from 'utils/TypeUtils';

import StyledInputHeader from 'views/Widgets/StyledInputHeader';

import './StyledInput.scss';

interface StyledInputProps extends Testable {
  children?: ReactNode;
  value: any;
  label?: string;
  isRequired?: boolean;
  error?: boolean;
  errorMessage?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  disabled?: boolean;
  type?: string;
  className?: string;
  step?: number;
  placeholder?: string;
  min?: any;
  max?: any;
  rounded?: boolean;
  showErrorOutside?: boolean;
  hint?: string;
  onFocus?: FocusEventHandler<any>;
  onBlur?: (
    event: FocusEvent<HTMLInputElement>,
    currentValue: string,
    valueAfterFocus: string
  ) => void;
  inputRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
}

const StyledInput: FC<StyledInputProps> = ({
  error,
  disabled,
  rounded,
  isRequired,
  label,
  errorMessage,
  type,
  value,
  onChange,
  onBlur,
  onFocus,
  children,
  className,
  inputRef,
  testHook,
  max,
  min,
  step,
  placeholder
}) => {
  const [valueAfterFocus, setValueAfterFocus] = useState('');

  return (
    <StyledInputWrapper
      error={error}
      disabled={disabled}
      rounded={rounded}
      isRequired={isRequired}
      label={label}
      errorMessage={errorMessage}
    >
      <Input
        type={type ? (type as InputType) : 'text'}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={className}
        min={min}
        max={max}
        step={step}
        placeholder={placeholder}
        data-test-hook={testHook}
        onFocus={(event) => {
          setValueAfterFocus(value);
          onFocus && onFocus(event);
        }}
        onBlur={(event) => onBlur && onBlur(event, value, valueAfterFocus)}
        innerRef={inputRef}
      />
      {children}
    </StyledInputWrapper>
  );
};

export const StyledInputWrapper: FC<
  Partial<StyledInputProps | PropsWithChildren<IRHFStyledInputProps>>
> = ({
  children,
  error,
  disabled,
  rounded,
  label,
  errorMessage,
  showErrorOutside,
  hint,
  className
}) => {
  return (
    <div
      className={`styled-input ${error ? 'error' : ''} ${disabled ? 'disabled' : ''} ${
        rounded ? 'rounded-borders' : ''
      } ${showErrorOutside ? 'show-outside-error' : ''} ${className}`}
    >
      {label && <StyledInputHeader error={error} errorMessage={errorMessage} text={label} />}
      <div className="input-area">
        {children}
        {hint && <div className="hint">{hint}</div>}
      </div>
    </div>
  );
};

interface IRHFStyledInputProps extends Testable {
  name: string;
  hint?: string;
  label?: string;
  isRequired?: boolean | string;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  type?: string;
  step?: number;
  placeholder?: string;
  min?: any;
  max?: any;
  maxLength?: number;
  minLength?: number;
  rounded?: boolean;
  register: UseFormRegister<any>;
  validate?: Validate<any, any> | Record<string, Validate<any, any>>;
  showErrorOutside?: boolean;
  readOnly?: boolean;
  className?: string;
  onBlur?: (
    event: FocusEvent<HTMLInputElement>,
    currentValue: string,
    valueAfterFocus: string
  ) => void;
  onFocus?: () => void;
  onChange?: (event: ChangeEvent, currentValue: string) => void;
  getValues?: UseFormGetValues<any>;
  id?: string;
}

export const RHFStyledInput = (props: IRHFStyledInputProps) => {
  const [valueAfterFocus, setValueAfterFocus] = useState('');

  let max = props.max;
  if (!max && props.type === 'date') {
    max = '2051-12-30';
  }

  const { ref, onChange, onBlur, ...rest } = props.register(props.name, {
    required: props.isRequired,
    min: props.min,
    max,
    maxLength: props.maxLength,
    minLength: props.minLength,
    validate: props.validate,
    valueAsNumber: props.type === 'number'
  });

  const onBlurHandler = (event: FocusEvent<HTMLInputElement>) => {
    onBlur(event);

    if (props.onBlur) {
      let currentValue = '';

      if (props.getValues) {
        currentValue = props.getValues(props.name);
      }

      props.onBlur(event, currentValue, valueAfterFocus);
    }
  };

  const onChangeHandler = (event: ChangeEvent) => {
    onChange(event);

    if (props.onChange) {
      let currentValue = '';

      if (props.getValues) {
        currentValue = props.getValues(props.name);
      }

      props.onChange(event, currentValue);
    }
  };

  return (
    <StyledInputWrapper {...props}>
      <Input
        id={props.id}
        type={props.type ? (props.type as InputType) : 'text'}
        disabled={props.disabled}
        min={props.min}
        max={max}
        maxLength={props.maxLength}
        minLength={props.minLength}
        step={props.step}
        placeholder={props.placeholder}
        innerRef={ref}
        {...rest}
        readOnly={props.readOnly}
        autoComplete="new-password"
        data-test-hook={props.testHook}
        onBlur={onBlurHandler}
        onFocus={() => {
          if (props.getValues) {
            setValueAfterFocus(props.getValues(props.name));
          }
        }}
        onChange={onChangeHandler}
      />
    </StyledInputWrapper>
  );
};

export default StyledInput;
