import { PathwayType } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';

export enum PathwayOptionOutcome {
  NONE = 0,
  AMBULANCE = 1,
  HOSPITAL = 2,
  CLINIC_EMERGENT = 5,
  CLINIC = 4,
  HOME_INSTRUCTIONS = 3
}

export enum PathwayOptionAction {
  DenyAll = 'DENY_ALL',
  AcceptAll = 'ACCEPT_ALL'
}

export enum PathwayQuestionTypes {
  SINGLE = 'Single',
  MULTIPLE = 'Multiple',
  TEXT = 'Text',
  TEXT_ARRAY = 'TextArray',
  DATE = 'Date'
}
export interface PathwayQuestionOutcome {
  optionId: string;
  score?: number;
  outcome: PathwayOptionOutcome;
  linkedPathway?: {
    id: string;
    urgency: LinkedPathwayUrgency;
  };
}

export interface PathwaySelectionAnswer {
  optionId: string;
  title: string;
  score?: number;
  outcome?: number;
  action?: PathwayOptionAction;
}

export enum PathwayDiagnosisAlertTypes {
  Dx = 'DX',
  Tx = 'TX'
}

export interface PathwayTextArrayAnswer {
  id: string;
  value: string;
  label?: string;
  title: string;
  outcome?: PathwayOptionOutcome;
  alertType: PathwayDiagnosisAlertTypes;
}

export interface PathwayQuestionOption {
  id: string;
  questionId: string;
}

export interface PathwayTemplateQuestion {
  id: string;
  isKey: boolean;
  outcomes: { [optionId: string]: PathwayQuestionOutcome };
  dependsOn?: string[];
  categoryId: string;
}

export interface PathwayIcdCodeSuggestion {
  code: string;
  name: string;
  id: number;
}

export interface PathwayQuestionCategory {
  id: string;
  title: string;
  showCondition: {
    type: PathwayCategoryConditionType;
    value: number;
    dependsOn?: string;
  };
  questions: PathwayTemplateQuestion[];
}

export interface PathwayTemplate {
  id: string;
  name: string;
  type: PathwayType;
  scoreToOutcomeMap: {
    [key: string]: PathwayOptionOutcome;
  };
  tooltipInfo: string;
  categories: PathwayQuestionCategory[];
  icdCodesSuggestions: PathwayIcdCodeSuggestion[];
  initialScore?: number;
  keywords?: string[];
}

export interface SearchedPathway extends PathwayTemplate {
  isMatchByName: boolean;
  matchedKeywords: string[];
}

export interface BaseQuestion {
  id: string;
  title: string;
  type: PathwayQuestionTypes;
  subtitle: string;
  optionIds: string[];
  withDxAlert?: boolean;
}

export enum LinkedPathwayUrgency {
  Low,
  High
}

export interface BaseOption {
  id: string;
  title: string;
  questionId: string;
  action?: PathwayOptionAction;
}

export interface PathwaysData {
  pathways: { [id: string]: PathwayTemplate };
  questions: { [id: string]: BaseQuestion };
  options: { [id: string]: BaseOption };
}

export interface PathwayBasicInfo {
  id: string;
  name: string;
  keywords: string[];
  type: PathwayType;
}

export interface PathwaySearchResult extends PathwayBasicInfo {
  isMatchByName: boolean;
  matchedKeywords: string[];
}

export enum PathwayCategoryConditionType {
  SCORE = 'SCORE',
  ALERT_EXACT = 'ALERT_EXACT',
  ALERT_MINIMUM = 'ALERT_MINIMUM'
}
