import { FC } from 'react';

import classNames from 'classnames';

import { ReactComponent as Chevron } from 'img/icons/arrow_selected.svg';
import { ReactComponent as AttentionToday } from 'img/icons/attention-today.svg';
import { ReactComponent as CalendarEmpty } from 'img/icons/calendar-empty.svg';
import { ReactComponent as Calendar } from 'img/icons/calendar.svg';
import { ReactComponent as Cancel } from 'img/icons/cancel.svg';
import { ReactComponent as CheckIndeterminate } from 'img/icons/check-indeterminate.svg';
import { ReactComponent as Checkbox } from 'img/icons/checkbox.svg';
import { ReactComponent as CheckedCheckbox } from 'img/icons/checked-checkbox.svg';
import { ReactComponent as CheckedRadio } from 'img/icons/checked-radio.svg';
import { ReactComponent as CheckMark } from 'img/icons/checkmark.svg';
import { ReactComponent as CircleEdit } from 'img/icons/circle-edit.svg';
import { ReactComponent as Cm } from 'img/icons/cm.svg';
import { ReactComponent as CollapseArrow } from 'img/icons/collapse-arrow.svg';
import { ReactComponent as Delete } from 'img/icons/delete.svg';
import { ReactComponent as DoubleArrow } from 'img/icons/double-arrow.svg';
import { ReactComponent as DownloadArrow } from 'img/icons/download-arrow.svg';
import { ReactComponent as DragIndicator } from 'img/icons/drag-indicator.svg';
import { ReactComponent as EllipsisOptions } from 'img/icons/ellipsis-options.svg';
import { ReactComponent as EpisodeEnded } from 'img/icons/episode-ended.svg';
import { ReactComponent as EpisodeError } from 'img/icons/episode-error.svg';
import { ReactComponent as EpisodeExpired } from 'img/icons/episode-expired.svg';
import { ReactComponent as Episode } from 'img/icons/episode.svg';
import { ReactComponent as Exclamation } from 'img/icons/exclamation.svg';
import { ReactComponent as ExternalLink } from 'img/icons/external-link.svg';
import { ReactComponent as FirstPageArrowPagination } from 'img/icons/first-page-arrow-pagination.svg';
import { ReactComponent as FreeText } from 'img/icons/free-text.svg';
import { ReactComponent as Help } from 'img/icons/help.svg';
import { ReactComponent as HighOperatorUrgency } from 'img/icons/high-operator-urgency.svg';
import { ReactComponent as ImmediateAttention } from 'img/icons/immediate-attention.svg';
import { ReactComponent as Info } from 'img/icons/information.svg';
import { ReactComponent as LandPhone } from 'img/icons/land-phone.svg';
import { ReactComponent as LastPageArrowPagination } from 'img/icons/last-page-arrow-pagination.svg';
import { ReactComponent as LinkToArrow } from 'img/icons/link-to-arrow.svg';
import { ReactComponent as MobilePhone } from 'img/icons/mobile-phone.svg';
import { ReactComponent as NextPageArrowPagination } from 'img/icons/next-page-arrow-pagination.svg';
import { ReactComponent as Notification } from 'img/icons/notification.svg';
import { ReactComponent as NurseReview } from 'img/icons/nurse-review.svg';
import { ReactComponent as OpenEllipsis } from 'img/icons/open-ellipsis.svg';
import { ReactComponent as Pause } from 'img/icons/pause.svg';
import { ReactComponent as PencilOutline } from 'img/icons/pencil-outline.svg';
import { ReactComponent as Pencil } from 'img/icons/pencil.svg';
import { ReactComponent as PhoneCallEnded } from 'img/icons/phone-call-ended.svg';
import { ReactComponent as PhoneCall } from 'img/icons/phone-call.svg';
import { ReactComponent as Pill } from 'img/icons/pill_ic.svg';
import { ReactComponent as Plus } from 'img/icons/plus-icon.svg';
import { ReactComponent as CirclePlus } from 'img/icons/plus.svg';
import { ReactComponent as PreviousPageArrowPagination } from 'img/icons/previous-page-arrow-pagination.svg';
import { ReactComponent as RadioButton } from 'img/icons/radio-button.svg';
import { ReactComponent as RefreshDisabled } from 'img/icons/refresh-icon-disabled.svg';
import { ReactComponent as Refresh } from 'img/icons/refresh-icon.svg';
import { ReactComponent as Remove } from 'img/icons/remove.svg';
import { ReactComponent as Resume } from 'img/icons/resume-timer.svg';
import { ReactComponent as SavedCall } from 'img/icons/saved-call.svg';
import { ReactComponent as Search } from 'img/icons/search.svg';
import { ReactComponent as Settings } from 'img/icons/settings.svg';
import { ReactComponent as StandardOperatorUrgency } from 'img/icons/standard-operator-urgency.svg';
import { ReactComponent as Unchecked } from 'img/icons/unchecked.svg';
import { ReactComponent as XBase } from 'img/icons/x-base.svg';
import { ReactComponent as XDisabled } from 'img/icons/x-disabled.svg';
import { ReactComponent as XPurple } from 'img/icons/x-purple.svg';
import { ReactComponent as XThin } from 'img/icons/x-thin.svg';
import { ReactComponent as XThick } from 'img/icons/x.svg';

import './Icon.scss';

// assumption: default (no rotation) equal to icon down rotation
interface RotationProps {
  up?: boolean;
  left?: boolean;
  right?: boolean;
}

// support full rotation (left, right, up, down). default icon state is down.
const withRotation = (IconComponent: React.ComponentType<any>) => {
  const IconWithRotation: FC<RotationProps & { className?: string }> = ({
    up,
    left,
    right,
    className,
    ...props
  }) => {
    const iconClasses = classNames(className, 'icon-with-rotation', { up, left, right });
    return <IconComponent className={iconClasses} {...props} />;
  };

  return IconWithRotation;
};

const Icon = {
  // TODO: move all icons from css to this component
  Cancel,
  XThin,
  XPurple,
  XDisabled,
  XThick,
  Refresh,
  RefreshDisabled,
  CalendarEmpty,
  Calendar,
  Cm,
  LandPhone,
  MobilePhone,
  Chevron: withRotation(Chevron),
  CollapseArrow: withRotation(CollapseArrow),
  DoubleArrow: withRotation(DoubleArrow),
  Info,
  CheckMark,
  CheckIndeterminate,
  CircleEdit,
  Exclamation,
  Episode,
  EpisodeEnded,
  EpisodeError,
  EpisodeExpired,
  Pill,
  Plus,
  Pencil,
  PencilOutline,
  SavedCall,
  Search,
  Pause,
  Resume,
  CirclePlus,
  Delete,
  PhoneCallEnded,
  PhoneCall,
  LinkToArrow,
  ExternalLink,
  Notification,
  EllipsisOptions,
  XBase,
  Unchecked,
  NextPageArrowPagination,
  PreviousPageArrowPagination,
  FirstPageArrowPagination,
  LastPageArrowPagination,
  OpenEllipsis,
  Checkbox,
  RadioButton,
  Remove,
  DragIndicator,
  CheckedRadio,
  CheckedCheckbox,
  FreeText,
  DownloadArrow,
  Help,
  Settings,
  ImmediateAttention,
  AttentionToday,
  NurseReview,
  HighOperatorUrgency,
  StandardOperatorUrgency
};

export type IconKey = keyof typeof Icon;

export default Icon;
