// @ts-strict-ignore
import { flow, groupBy as fpGroupBy, keys, uniq } from 'lodash/fp';
import { action, computed, observable, makeObservable, toJS } from 'mobx';

import moment from 'moment';

import { DataMap } from 'mobx/stores';

import ObjectCopier from 'utils/ObjectCopier';
import { textWithLineBreaks } from 'utils/StringUtils';

import { DurationDoctorDetails, DurationInterval } from 'models/DurationInterval';
import { SymptomType } from 'models/TicketTypes';

import { RemoteMonitoringStatus } from 'views/Filters/FilterFields';

import CallLogIntervalAuditTrailRecord from './CallLogIntervalAuditTrailRecord';
import CallReason, { CallTopicDiscussed, ICallReasonTypes } from './CallReason';
import OrderedCallPathwayAnswer from './OrderedCallPathwayAnswer';
import { PathwayQuestionSummary } from './PathwayQuestionSummary';
import Patient from './Patient';
import QuestionnaireAnswer from './QuestionnaireAnswer';
import SeverityReportedCause, { isSeverityReportedCause } from './ReportedCause';

export const MAX_TOTAL_CALL_DURATION_IN_HOURS = 10;
const TOTAL_CALL_TIMER_MAX_HOURS = 1;

export enum IncludeSummaryInEmrNote {
  Yes = 'yes',
  No = 'no',
  DontOfferAgain = 'dontOfferAgain'
}

export interface CallClinician {
  id: number;
  firstName: string;
  lastName: string;
  credentialId: number;
}

export interface ICall {
  id: number;
  callDuration: number;
  documentationDuration: number;
  doctorId: number;
  patientId: number;
  note: string;
  lastReportId: number;
  patientInitiated: boolean;
  createdAt: Date;
  updatedAt: Date;
  durationIntervals: DurationInterval[];
  auditTrail: CallLogIntervalAuditTrailRecord[];
  callReasons: CallReason[];
  topicsDiscussed?: CallTopicDiscussed[];
  info: ICallInfo;
  orderedPathwayAnswers: OrderedCallPathwayAnswer[];
  isDraft: boolean;
  ticketIds: Set<number>;
  documentId: number;
  summary?: string;
  includeSummaryInEmrNote?: IncludeSummaryInEmrNote;
  smartSummaryId?: number | null;
  isSummaryManuallyEdited?: boolean;
  clinician: CallClinician;
}

export default class Call extends ObjectCopier {
  id: number;

  doctorId: number;

  patientId: number;

  callDuration: number;

  documentationDuration: number;

  @observable
  note: string;

  lastReportId: number;

  patientInitiated: boolean;

  createdAt: Date;

  updatedAt: Date;

  durationIntervals: CallDurationInterval[];

  auditTrail: CallLogIntervalAuditTrailRecord[];

  callReasons: CallReason[];

  topicsDiscussed: CallTopicDiscussed[];

  info: ICallInfo;

  orderedPathwayAnswers: OrderedCallPathwayAnswer[];

  isDraft: boolean;

  pathwayQuestionSummary?: PathwayQuestionSummary[];

  @observable
  ticketIds: Set<number>;

  @observable
  documentId: number;

  @observable
  summary?: string;

  @observable
  includeSummaryInEmrNote?: IncludeSummaryInEmrNote | null;

  @observable
  smartSummaryId?: number | null;

  @observable
  isSummaryManuallyEdited?: boolean;

  @observable
  clinician: CallClinician;

  constructor(data: ICall) {
    super();
    makeObservable(this);
    Object.assign(this, data);
  }

  @computed
  get durationText() {
    return moment.duration((this.callDuration + this.documentationDuration) * 1000).humanize();
  }

  @computed
  get isSentToEMR() {
    return Boolean(this.documentId);
  }

  @computed
  get notesWithLineBreaks() {
    return textWithLineBreaks(this.note);
  }

  @action
  deleteTicket(id: number) {
    if (!this.ticketIds) {
      return;
    }
    this.ticketIds.delete(id);
  }

  @action
  addTicket(ticketId: number) {
    if (!this.ticketIds) {
      this.ticketIds = new Set();
    }
    this.ticketIds.add(ticketId);
  }

  @computed
  get reasonNames() {
    return this.callReasons
      ?.filter((reason) => reason.type === ICallReasonTypes.ICD_CODE)
      .map((reason) => reason.text);
  }

  @computed
  get pathwayNames() {
    if (!this.orderedPathwayAnswers.length) {
      return null;
    }

    const pathwayNames = flow(fpGroupBy('pathwayName'), keys, uniq)(this.orderedPathwayAnswers);

    return pathwayNames.sort();
  }

  @computed
  // Note: this property is not available for Draft Calls
  get ticketIdsArray() {
    return this.ticketIds ? Array.from(this.ticketIds) : [];
  }

  toJSCall() {
    const call = toJS(this);

    return {
      ...call,
      ticketIds: this.ticketIdsArray
    };
  }
}

// TODO: remove when documentation is no longer needed as backward compatibility
export enum DurationTypeEnum {
  CALL = 'call',
  DOCUMENTATION = 'documentation'
}
// TODO: remove when documentation is no longer needed for backward compatibility
export class CallDurationInterval extends DurationInterval {
  type: DurationTypeEnum;

  // for backward compatibility
  static fromDurationInterval(interval: DurationInterval) {
    return new CallDurationInterval(
      interval.startDate,
      interval.endDate,
      interval.id,
      DurationTypeEnum.CALL,
      interval.doctorDetails,
      interval.uniqueIdentifier,
      interval.isCallAttempt
    );
  }

  constructor(
    startDate: Date,
    endDate: Date,
    id: number,
    type: DurationTypeEnum,
    doctorDetails: DurationDoctorDetails,
    identifierFallback?: string,
    isCallAttempt?: boolean
  ) {
    super(doctorDetails, startDate, endDate, id, isCallAttempt);
    if (identifierFallback) {
      this.identifierFallback = identifierFallback;
    }
    this.type = type;
  }
}

export enum CallDurationValidationErrorEnum {
  MAXIMUM_DURATION_REACHED,
  INACCURATE_DURATION
}

interface ILastReportInfo {
  lastReportName: string;
  lastReportDate: string;
  reportBullets: Array<{ title: string; value: string }>;
}

interface ICallInfo {
  patientName: string;
  mrn: string;
  dateOfBirth: string;
  phone: string;
  lastReportInfo: ILastReportInfo;
  remoteMonitoringStatus: RemoteMonitoringStatus;
}

// Helpers

export const getCallInfo = (patient: Patient, symptomsMap: DataMap<SymptomType>): ICallInfo => {
  return {
    patientName: patient.fullName,
    phone: patient.phone ? patient.phone : ' - ',
    mrn: patient.mrn ? patient.mrn : ' - ',
    dateOfBirth: patient.dateOfBirth ? moment.utc(patient.dateOfBirth).format('MM/DD/YYYY') : ' - ',
    lastReportInfo: patient.lastReport ? getLastReportInfo(patient.lastReport, symptomsMap) : null,
    remoteMonitoringStatus: patient.remoteMonitoringStatus
  };
};

export const getLastReportInfo = (
  report: QuestionnaireAnswer,
  symptomsMap: DataMap<SymptomType>
): ILastReportInfo => {
  const unbearableCauses: SeverityReportedCause[] = [];
  const severeCauses: SeverityReportedCause[] = [];
  const moderateCauses: SeverityReportedCause[] = [];
  const mildCauses: SeverityReportedCause[] = [];
  if (report.answer.causes) {
    report.answer.causes
      .filter((cause) => isSeverityReportedCause(cause))
      .forEach((cause: SeverityReportedCause) => {
        switch (cause.severity) {
          case 1:
            mildCauses.push(cause);
            break;
          case 2:
            moderateCauses.push(cause);
            break;
          case 3:
            severeCauses.push(cause);
            break;
          case 4:
            unbearableCauses.push(cause);
            break;
          default:
            throw new Error('Unknown severity!');
        }
      });
    const bullets = Array<{ title: string; value: string }>();
    if (report.answer.distressLevel) {
      bullets.push({
        title: 'Distress Thermometer Score:',
        value: report.answer.distressLevel.toString()
      });
    }
    if (unbearableCauses.length > 0) {
      bullets.push({
        title: 'Unbearable:',
        value: unbearableCauses
          .map((cause) => symptomsMap.get(cause.causeId)?.name || '')
          .join(', ')
      });
    }
    if (severeCauses.length > 0) {
      bullets.push({
        title: 'Severe:',
        value: severeCauses
          .map((cause) => {
            return symptomsMap.get(cause.causeId)?.name || '';
          })
          .join(', ')
      });
    }
    if (moderateCauses.length > 0) {
      bullets.push({
        title: 'Moderate:',
        value: moderateCauses
          .map((cause) => {
            return symptomsMap.get(cause.causeId)?.name || '';
          })
          .join(', ')
      });
    }
    if (mildCauses.length > 0) {
      bullets.push({
        title: 'Mild:',
        value: mildCauses
          .map((cause) => {
            return symptomsMap.get(cause.causeId)?.name || '';
          })
          .join(', ')
      });
    }
    return {
      lastReportName: report.name,
      lastReportDate: moment(report.createdAt).format('MM/DD/YYYY'),
      reportBullets: bullets
    };
  }
};

export const getCallDurationErrorOrNull = (
  callDurationInSeconds: number
): CallDurationValidationErrorEnum | null => {
  if (callDurationInSeconds / 3600 > MAX_TOTAL_CALL_DURATION_IN_HOURS) {
    return CallDurationValidationErrorEnum.MAXIMUM_DURATION_REACHED;
  }

  if (callDurationInSeconds / 3600 > TOTAL_CALL_TIMER_MAX_HOURS) {
    // Call over an hour long (call timer + documentation timer together).
    return CallDurationValidationErrorEnum.INACCURATE_DURATION;
  }

  return null;
};
