import { compact } from 'lodash/fp';
import moment from 'moment';

export function isSubStringCaseInsensitive(str: string, subString: string) {
  return str.toLowerCase().indexOf(subString.toLowerCase()) !== -1;
}

export function pluralize(word: string, number: number): string {
  if (word) {
    return `${word}${number === 1 ? '' : 's'}`;
  }
  return '';
}

export function textWithLineBreaks(text: string = '') {
  return text.replace(/(\r\n|\n|\r)/gm, '<br />');
}

export const joinStringArrayByDelimiter = (values: string[], delimiter = ' • ') => {
  return compact(values).join(delimiter);
};

export const getNumberAsOrdinal = (num: number) => {
  return moment.localeData().ordinal(num);
};

// comparing and sorting
export const stringCaseInsensitiveCompare = (str1: string, str2: string) =>
  // accent will ignore casing, see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator/Collator#parameters
  str1.localeCompare(str2, 'en', { sensitivity: 'accent' });

export const createStringSortFn = <T>(extractPropFn?: (source: T) => string) => {
  return (a: T, b: T) => {
    const str1: string = extractPropFn ? extractPropFn(a) : ((a as unknown) as string);
    const str2 = extractPropFn ? extractPropFn(b) : ((b as unknown) as string);
    return stringCaseInsensitiveCompare(str1, str2);
  };
};

export const removeSpacesFromString = (str: string): string => {
  return str.replace(/\s/g, '');
};

export const isStringInPhrase = (string: string, phrase: string): boolean => {
  const phraseToSearch = new RegExp(`\\b${string}\\b`);
  return phraseToSearch.test(phrase);
};
