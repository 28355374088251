import { ProtocolType } from 'models/ScheduledProtocol';

export const sharedTestSelectors = {
  lightweightPathwayQuestionContainer: (questionId: string) =>
    `lightweight-pathway-question-${questionId}`,
  lightweightPathwaySingleQuestionOption: (optionId: string) =>
    `lightweight-pathway-single-question-answer-${optionId}`,
  lightweightPathwayMultipleQuestionOption: (optionId: string) =>
    `lightweight-pathway-multiple-question-answer-${optionId}`,
  lightweightPathwayTextQuestionInput: (questionId: string) =>
    `lightweight-pathway-text-question-input-${questionId}`,
  reportProtocolSelectButton: 'report-protocol-select-button',
  reportProtocolSelectMenu: 'report-protocol-select-button-menu',
  reportProtocolSelectValue: 'report-protocol-select-button-value',
  reportProtocolSelectOption: (option: ProtocolType) => `report-protocol-select-button-${option}`,
  switchProtocolPopup: 'switch-protocol-popup',
  firstNameInput: 'first-name-input',
  lastNameInput: 'last-name-input',
  mrnInput: 'mrn-input',
  dobInput: 'dob-input',
  sexSelect: 'sex-select',
  languageSelect: 'language-select',
  phoneNumberInput: 'phoneNumberInput',
  phoneTypeSelect: 'phone-type-select',
  primaryProviderSelect: 'primary-provider-select',
  primaryLocationSelect: 'primary-location-select',
  serviceConsentCheckbox: 'service-consent-checkbox',
  copayConsentCheckbox: 'copay-consent-checkbox',
  readTermsCheckbox: 'read-terms-checkbox',
  enrollmentStatus: {
    chemoTech: 'enrollment-status-chemo-teach',
    established: 'enrollment-status-established'
  }
};
