// @ts-strict-ignore
import { ErrorName, OverlappingIntervalsFailure, transformErrorUiProps } from 'errors';

import { TicketResolutionResponse } from 'fetchers/TicketsFetcher';

import HttpService from 'services/HttpService';

import {
  getCallOverlapErrorMessage,
  getIntervalOverlapErrorMessage
} from 'utils/IntervalValidationUtils';

import { API_LABELS, API_URLS } from 'constants/apiUrls';

import { HttpStatusCode } from 'constants/httpStatusCode.const';

import { CallsParser } from 'parsers/CallsParser';

import ParseServerResponseService from 'parsers/ParseServerResponseService';

import Call from 'models/Call';
import OrderedCallPathwayAnswer from 'models/OrderedCallPathwayAnswer';

export const callCommonIncludes = ['callIntervals', 'pathwayAnswers', 'ticketsToCalls'];
const DRAFT_NOT_EXIST = 'This draft call no longer exists. Please refresh your browser.';

export interface CallResolutionResponse extends TicketResolutionResponse {
  call: Call;
}

export interface FetchCallsQuery {
  includes?: string[];
  callIds: number[];
}

export interface GenerateSmartSummaryResponse {
  summary: string;
  smartSummaryId: number;
}

const httpService = new HttpService('call', true);

export class CallsFetcher {
  static async fetchCallsForTickets(ticketIds: number[]): Promise<Call[]> {
    return await httpService.get({
      url: API_URLS.CALLS,
      networkLabel: API_LABELS.CALLS(ticketIds),
      query: { ticketIds, includes: callCommonIncludes },
      transformResponse: (items) => items.map(CallsParser.parseCall),
      transformError: transformErrorUiProps('Failed to fetch calls for tickets')
    });
  }

  static async fetchCallsByQuery(query: FetchCallsQuery): Promise<Call[]> {
    return await httpService.get({
      url: API_URLS.CALLS_QUERY,
      query: { includes: callCommonIncludes, ...query },
      transformResponse: (items) => items.map(CallsParser.parseCall)
    });
  }

  static async saveCall(
    call: Call,
    shouldSendToEmr: boolean,
    shouldResolveIncludedTickets: boolean
  ): Promise<CallResolutionResponse> {
    return await httpService.post({
      url: API_URLS.SAVE_PATIENT_CALL(call.patientId),
      data: { call, shouldSendToEmr, shouldResolveIncludedTickets },
      transformResponse: CallsParser.parseSaveCallResponse,
      transformError: (error) => {
        if (error.name === ErrorName.SummaryCharacterLimit) {
          return error;
        }

        if (error.name === ErrorName.TicketConnectedToDraft) {
          return error;
        }
        // default title
        error.ui.title = 'Failed to save call';

        const failedOnTicketResolution =
          error.name === ErrorName.ResolveTicketsConflict ||
          error.name === ErrorName.ResolveClosedTicket;

        if (error.name === ErrorName.OverlappingIntervals) {
          const { overlappingPatient } = error.httpFailure
            .serverData as OverlappingIntervalsFailure;
          error.ui.title = 'This Call Overlaps with Another Call';
          error.ui.description = getCallOverlapErrorMessage(overlappingPatient);
        } else if (shouldSendToEmr && !failedOnTicketResolution) {
          error.name = ErrorName.FailedSendingCallToEmr;
          error.ui.title = 'Call Failed to Send to EMR';
        }

        return error;
      }
    });
  }

  static async updateSavedCall(
    call: Call,
    shouldResolveIncludedTickets: boolean
  ): Promise<CallResolutionResponse> {
    return await httpService.put({
      url: API_URLS.UPDATE_SAVED_CALL(call.id),
      data: { call: call.toJSCall(), shouldResolveIncludedTickets },
      transformResponse: CallsParser.parseSaveCallResponse
    });
  }

  static async createDraftCall(call: Call): Promise<any> {
    return await httpService.post({
      url: API_URLS.CREATE_PATIENT_DRAFT_CALL(call.patientId),
      data: { call },
      transformResponse: (response) => response.call.id
    });
  }

  static async updateDraftCall(call: Call): Promise<any> {
    return await httpService.put({
      url: API_URLS.UPDATE_PATIENT_DRAFT_CALL(call.patientId, call.id),
      data: { call },
      transformResponse: ParseServerResponseService.parsePatientContact,
      transformError: (error) => {
        // why here  BAD_REQUEST and on delete NOT_FOUND?
        if (error.isStatusCodeEquals(HttpStatusCode.BAD_REQUEST)) {
          error.ui.description = DRAFT_NOT_EXIST;
          error.name = ErrorName.DraftCallActionFailed;
        }
        return error;
      }
    });
  }

  static async disconnectDraftsFromTickets(ticketIds: number[], excludedCallIds?: number[]) {
    return await httpService.post({
      url: API_URLS.DISCONNECT_DRAFTS_FROM_TICKETS,
      data: { ticketIds, excludedCallIds }
    });
  }

  static async deleteDraftCall(call: Call): Promise<any> {
    return await httpService.delete({
      url: API_URLS.DELETE_PATIENT_DRAFT_CALL(call.patientId, call.id),
      transformError: (error) => {
        if (error.isStatusCodeEquals(HttpStatusCode.NOT_FOUND)) {
          error.ui.description = DRAFT_NOT_EXIST;
          error.name = ErrorName.DraftCallActionFailed;
        }
        return error;
      }
    });
  }

  static async validateInterval(
    startedAt: Date,
    endedAt: Date,
    patientId: number
  ): Promise<string | null> {
    try {
      return await httpService.post({
        url: API_URLS.VALIDATE_CALL_INTERVAL(patientId),
        data: { startedAt, endedAt },
        transformResponse: () => null // if status is OK - no error should be returned
      });
    } catch (error) {
      if (error.name === ErrorName.OverlappingIntervals) {
        const { overlappingPatient } = error.httpFailure.serverData as OverlappingIntervalsFailure;
        return getIntervalOverlapErrorMessage(overlappingPatient); // return error message based on server data
      }
      error.ui.title = 'Unknown Error on call interval validation';
      throw error;
    }
  }

  static async generateSmartSummary(
    requestBody: OrderedCallPathwayAnswer[]
  ): Promise<GenerateSmartSummaryResponse> {
    return await httpService.post<GenerateSmartSummaryResponse>({
      url: API_URLS.GENERATE_CALL_SMART_SUMMARY,
      data: { pathwayAnswers: requestBody }
    });
  }
}
