// @ts-strict-ignore
import { isNil } from 'lodash/fp';

import { DataMap } from 'mobx/stores';

import MissedDosesReasons from 'models/MissedDosesReasons';

import { ReportQuestionAnswer } from 'models/QuestionnaireAnswerOral';

import { parseMissedDosesReasons } from 'views/Patient/PatientMain/ReportsTable/useReportNormalization';

export function getMissedDosesReasonsText(
  missedDoses: ReportQuestionAnswer<number>,
  missedDosesReasonsMap: DataMap<MissedDosesReasons>
) {
  const reasons = [];
  if (missedDoses?.additions.length > 0) {
    reasons.push(...parseMissedDosesReasons(missedDoses.additions, missedDosesReasonsMap));
  }
  if (!isNil(missedDoses?.customMessage)) {
    reasons.push(missedDoses.customMessage || 'Other');
  }
  return reasons.join(', ');
}

export function getRegimenText(regimen: string, isCustom: boolean): string {
  return `${regimen}${isCustom ? ' - Custom' : ''}`;
}
