import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { FieldValues, UseFormReturn } from 'react-hook-form';

import useSet from 'react-use/lib/useSet';

import { connectTicketsToCallsModalSelectors } from 'tests/models/components/connect-tickets-to-calls-modal/connect-tickets-to-calls-modal.selectors';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';

import { clusterTicketsForCallConnect, TicketsConnectCluster } from 'utils/TicketClusteringUtils';

import { API_LABELS } from 'constants/apiUrls';

import Call from 'models/Call';
import Patient from 'models/Patient';

import Ticket from 'models/Ticket';

import { CollapsibleSection } from 'views/Widgets/CollapsibleSection';

import ConnectedTicketActions from 'components/Ticket/TicketRow/ConnectedTicketActions';
import TicketOverviewProvider from 'components/Ticket/TicketsContainers/TicketOverviewProvider';
import TicketsList from 'components/Ticket/TicketsContainers/TicketsList';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

interface ConnectTicketToCallsProps extends UseConnectTicketToCallsStateReturnProps {}

interface UseConnectTicketToCallsStateReturnProps {
  callTicketIds: number[];
  connectedTicketIds: Set<number>;
  connectTicketToCall: (key: number) => void;
  resetConnectedTickets: () => void;
  disconnectTicketFromCall: (key: number) => void;
  ticketsClusters: TicketsConnectCluster;
  isLoading: boolean;
  assignCallback: () => void;
}

export const useConnectTicketToCallsState = (
  call: Call | Partial<Call>,
  patient: Patient,
  isModalOpen: boolean
): UseConnectTicketToCallsStateReturnProps => {
  const { ticketsStore } = useStores();
  const isLoading = useNetworkLoading(API_LABELS.TICKETS_BY_PATIENT(patient?.id));
  const [tickets, setTickets] = useState<Ticket[]>();

  if (!call.ticketIds) {
    console.warn(
      'No ticketIds on call, this will cause an error displaying currently connected tickets'
    );
  }

  const searchTicketsForModal = useCallback(async () => {
    const tickets = await ticketsStore.searchTickets(
      {
        ticketStatuses: Ticket.TICKET_ACTIVE_STATUSES,
        patientId: patient.id
      },
      'connect-tickets-to-call-modal-search'
    );

    setTickets(tickets);
  }, [patient?.id, ticketsStore]);

  useEffect(
    function fetchTicketsForModal() {
      if (isModalOpen) {
        searchTicketsForModal();
      }
    },
    [searchTicketsForModal, isModalOpen]
  );

  const [
    connectedTicketIds,
    { add: connectTicketToCall, remove: disconnectTicketFromCall, reset: resetConnectedTickets }
  ] = useSet(new Set(call.ticketIds));

  const editedCall = useMemo(
    () => ({
      ...call,
      ticketIds: connectedTicketIds
    }),
    [call, connectedTicketIds]
  );

  const ticketsClusters = useMemo(() => {
    return clusterTicketsForCallConnect(tickets || [], patient, editedCall);
  }, [editedCall, patient, tickets]);

  return {
    callTicketIds: Array.from(editedCall.ticketIds),
    connectedTicketIds,
    connectTicketToCall,
    resetConnectedTickets,
    disconnectTicketFromCall,
    ticketsClusters,
    isLoading,
    assignCallback: searchTicketsForModal
  };
};

export const ConnectTicketToCalls = ({
  ticketsClusters,
  connectedTicketIds,
  connectTicketToCall,
  disconnectTicketFromCall,
  assignCallback
}: ConnectTicketToCallsProps) => {
  const { actionCallbacks } = useTicketOverviewContext();

  return (
    <TicketOverviewProvider
      // The rare case where we provide a callback to a descendent TicketOverviewProvider
      // This modal is rendered in different contexts and can potentially call a reassign action
      // The changes of reassign should be reflected inside the modal and out
      actionCallbacks={actionCallbacks}
      ticketActions={({ ticket }) => (
        <ConnectedTicketActions
          ticket={ticket}
          connectedTicketIds={connectedTicketIds}
          connectTicketToCall={connectTicketToCall}
          disconnectTicketFromCall={disconnectTicketFromCall}
          assignCallback={assignCallback}
        />
      )}
    >
      <>
        <CollapsibleSection
          name="Connected Tickets"
          trigger={
            <div className="report-section-header">
              <div>Connected Tickets ({ticketsClusters.connectedTickets.length})</div>
            </div>
          }
          initiallyOpen
        >
          <TicketsList tickets={ticketsClusters.connectedTickets} />
        </CollapsibleSection>

        <CollapsibleSection
          name="Open Tickets"
          trigger={
            <div className="report-section-header">
              Open Tickets ({ticketsClusters.openTickets.length})
            </div>
          }
          initiallyOpen
          className="open-tickets-section"
        >
          <TicketsList tickets={ticketsClusters.openTickets} />
        </CollapsibleSection>
      </>
    </TicketOverviewProvider>
  );
};

interface TicketsCallConnectModalWrapperProps {
  isOpen: boolean;
  onSave: (shouldResolve: boolean) => void;
  onClose: () => void;
  resetDataAfterClose: () => void;
  children: ReactNode;
  showResolveButton?: boolean;
  isLoading?: boolean;
  canSubmit?: boolean;
  methods: UseFormReturn<any>;
  defaultValues: FieldValues | null;
}
export const TicketsCallConnectModalWrapper = ({
  isOpen,
  onSave,
  onClose,
  isLoading,
  showResolveButton,
  resetDataAfterClose,
  children,
  methods,
  defaultValues
}: TicketsCallConnectModalWrapperProps) => {
  const save = () => onSave(false);
  const saveAndResolve = () => onSave(true);

  return (
    <FormModal
      defaultValues={defaultValues}
      methods={methods}
      isOpen={isOpen}
      title="Which tickets would you like to connect to this call?"
      confirmActions={[
        {
          onClick: saveAndResolve,
          disabled: Boolean(isLoading) || !showResolveButton,
          text: isLoading ? 'Saving...' : 'Save & Resolve'
        },
        {
          onClick: save,
          disabled: Boolean(isLoading),
          text: isLoading ? 'Saving...' : 'Save',
          testHook: connectTicketsToCallsModalSelectors.submitButton
        }
      ]}
      closeAction={{ onClick: onClose, disabled: false }}
      size="large"
      subtitle="Tickets are submitted to the EMR with connected calls as a single document."
      resetDataAfterClose={resetDataAfterClose}
      testHook={connectTicketsToCallsModalSelectors.container}
    >
      <div className="tickets-call-connect-modal">{children}</div>
    </FormModal>
  );
};
