import { FC } from 'react';

import { StyledHtmlContentContainer, StyledInlineHtmlContentContainer } from 'utils/html.styles';

interface Props {
  children: string;
  inline?: boolean;
}

export const HtmlContent: FC<Props> = ({ children, inline = false }) =>
  inline ? (
    <StyledInlineHtmlContentContainer dangerouslySetInnerHTML={{ __html: children }} />
  ) : (
    <StyledHtmlContentContainer dangerouslySetInnerHTML={{ __html: children }} />
  );
