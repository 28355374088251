// @ts-strict-ignore
import { Fragment, ReactNode } from 'react';

import { Box, css, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { sortBy } from 'lodash/fp';
import { observer } from 'mobx-react';
import moment from 'moment';

import { useStores } from 'mobx/hooks/useStores';

import { addDecimalToICDCodeIfNeeded } from 'utils/IcdCodesUtils';

import Call, { getCallInfo } from 'models/Call';
import CallPathwayAnswer from 'models/CallPathwayAnswer';
import CallReason, { ICallReasonTypes } from 'models/CallReason';

import OrderedCallPathwayAnswer from 'models/OrderedCallPathwayAnswer';
import {
  PathwayQuestionTypes,
  PathwaySelectionAnswer,
  PathwayOptionAction,
  PathwayTextArrayAnswer
} from 'models/PathwayTemplates';
import Patient from 'models/Patient';

import Ticket from 'models/Ticket';

import SanitizeHtml from 'views/Widgets/SanitizeHtml';

import GeneratedConnectedTicketsView from './GeneratedConnectedTicketsView';

export interface IGeneratedCallDetailsView {
  call: Call | Partial<Call>;
  patient: Patient;
  primarySymptoms: CallReason[];
  pathwayAnswers: OrderedCallPathwayAnswer[];
  connectedTickets?: Ticket[];
  isGenerateSmartSummaryPreferenceEnabled?: boolean;
}

const GeneratedCallDetailsView = (props: IGeneratedCallDetailsView) => {
  const { pathwaysStore, constantsStore } = useStores();
  const {
    patient,
    call,
    pathwayAnswers,
    primarySymptoms,
    connectedTickets,
    isGenerateSmartSummaryPreferenceEnabled
  } = props;

  let primarySymptomsCodesOnly: CallReason[] = [];
  if (primarySymptoms) {
    primarySymptomsCodesOnly = primarySymptoms.filter(
      (callReason) =>
        callReason.type === ICallReasonTypes.ICD_CODE || callReason.type === ICallReasonTypes.OTHER
    );
    sortBy(['icdCodeCode'], primarySymptomsCodesOnly);
  }

  const hasPrimarySymptoms = primarySymptomsCodesOnly && primarySymptomsCodesOnly.length > 0;

  const hasTopicsDiscussed = call ? call.topicsDiscussed.length > 0 : false;
  const callInfo = call ? call.info : getCallInfo(patient, constantsStore.symptomsMap);

  const answersByPathway: {
    [pathwayId: string]: CallPathwayAnswer[];
  } = pathwaysStore.answersSortedByPathway(pathwayAnswers);
  const showCallSummary = isGenerateSmartSummaryPreferenceEnabled && call.summary;

  const calculatePathwayScore = (pathwayAnswers: CallPathwayAnswer[]) => {
    const hasScore = pathwayAnswers.some((answer) => answer.value.score >= 0);
    let pathwayScore = 0;
    if (hasScore) {
      pathwayScore = pathwayAnswers.reduce((score, answer) => {
        if (answer.value.score >= 0) {
          return score + answer.value.score;
        }
        return score;
      }, 0);
    }
    return { hasScore, score: pathwayScore };
  };

  const renderAnswerValue = (callAnswer: CallPathwayAnswer): ReactNode => {
    switch (callAnswer.questionType) {
      case PathwayQuestionTypes.SINGLE:
        let scoreText = null;
        if (callAnswer.value.score >= 0) {
          scoreText = `(${callAnswer.value.score} point${callAnswer.value.score !== 1 ? 's' : ''})`;
        }
        return (
          <Fragment key={callAnswer.value.id}>
            A: <SanitizeHtml wrapperTag="span" html={callAnswer.value.title} /> {scoreText || ''}
          </Fragment>
        );
      case PathwayQuestionTypes.MULTIPLE: {
        const answers = callAnswer.value.map((answer: PathwaySelectionAnswer) => {
          if (answer.action === PathwayOptionAction.AcceptAll) {
            return null;
          }
          return (
            <div key={answer.optionId}>
              A: <SanitizeHtml wrapperTag="span" html={answer.title} />
            </div>
          );
        });

        const deniedAnswers =
          callAnswer.deniedOptions?.map((option) => (
            <Fragment key={option.id}>
              Denies: <SanitizeHtml wrapperTag="span" html={option.title} />
              <br />
            </Fragment>
          )) || [];

        return [...answers, ...deniedAnswers];
      }

      case PathwayQuestionTypes.TEXT:
        return `A: ${callAnswer.value}`;
      case PathwayQuestionTypes.DATE:
        return `A: ${moment(callAnswer.value).format('MM/DD/YYYY')}`;
      case PathwayQuestionTypes.TEXT_ARRAY:
        return callAnswer.value.map((answer: PathwayTextArrayAnswer) => (
          <div key={answer.id}>A: {answer.title}</div>
        ));
      default:
        throw new Error(`Unknown question type ${callAnswer.questionType}`);
    }
  };

  const renderIcdCodesAndTopicsDiscussed = () => (
    <>
      {hasPrimarySymptoms && (
        <Box my={20}>
          <div>
            <Typography variant="body1">ICD-10 Codes:</Typography>
          </div>

          {primarySymptomsCodesOnly.map((callReason) => (
            <Box ml={8} key={callReason.icdCodeId}>
              <Typography variant="body2">
                &bull;{' '}
                {`${
                  callReason.icdCodeCode
                    ? `${addDecimalToICDCodeIfNeeded(callReason.icdCodeCode)}:`
                    : ''
                } ${callReason.text}`}
              </Typography>
            </Box>
          ))}
        </Box>
      )}

      {hasTopicsDiscussed && (
        <Box my={20}>
          <div>
            <Typography variant="body1">Topics Discussed:</Typography>
          </div>

          {call.topicsDiscussed.map((topic) => (
            <Box ml={8} key={`${topic.parentId}_${topic.id}`}>
              <Typography variant="body2">
                &bull; {topic.parentName}: {topic.name}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </>
  );

  return (
    <StyledContainer className="expain-generated-details">
      <Box mb={20}>
        <div>
          <Typography variant="body1">{callInfo.patientName}</Typography>
          <Typography variant="body2">
            , MRN: {callInfo.mrn || '-'}, DOB: {moment.utc(callInfo.dateOfBirth).format('M/D/YYYY')}
          </Typography>
        </div>

        <div>
          <Typography variant="body2">
            Remote Monitoring Status: {callInfo.remoteMonitoringStatus}
          </Typography>
        </div>

        <div>
          <Typography variant="body2">Phone: {callInfo.phone}</Typography>
        </div>
      </Box>

      {connectedTickets?.length > 0 && (
        <GeneratedConnectedTicketsView connectedTickets={connectedTickets} />
      )}

      {call && (
        <Box my={20}>
          <div>
            <Typography variant="body1">Call {call.id}</Typography>
          </div>

          {call.durationIntervals.map((durationInterval) => (
            <Box key={durationInterval.startDate.getTime()} ml={8}>
              <Typography variant="body2">
                &bull; {durationInterval.doctorDetails.name}:{' '}
                {moment(durationInterval.startDate).format(`M/D/YYYY [at] h:mm A`)} -{' '}
                {moment(durationInterval.endDate).format(`M/D/YYYY [at] h:mm A`)}
              </Typography>
            </Box>
          ))}

          <Box ml={8}>
            <Typography variant="body2">&bull; Total Duration: {call.durationText}</Typography>
          </Box>
        </Box>
      )}

      {!showCallSummary && renderIcdCodesAndTopicsDiscussed()}

      {showCallSummary && (
        <Box my={20}>
          <div>
            <Typography variant="body1">
              Call Summary (Auto-Generated, Manually Reviewed):
            </Typography>
          </div>

          <div>
            <Typography variant="body2">{call.summary}</Typography>
          </div>
        </Box>
      )}

      {call && (
        <Box my={20}>
          <div>
            <Typography variant="body1">
              {showCallSummary ? 'Additional Notes' : 'Call Notes'}:
            </Typography>
          </div>

          <Box>
            {call.notesWithLineBreaks && (
              <SanitizeHtml html={call.notesWithLineBreaks} className="generated-row" />
            )}
          </Box>
        </Box>
      )}

      {Object.values(answersByPathway).map((callPathwayAnswers: CallPathwayAnswer[]) => {
        const { hasScore, score } = calculatePathwayScore(callPathwayAnswers);

        return (
          <div key={callPathwayAnswers[0].pathwayId} className="pathway-section">
            <Box mt={20} mb={2}>
              <div>
                <Typography variant="body1">
                  Pathway: {callPathwayAnswers[0].pathwayName} {hasScore ? `(Score: ${score})` : ''}
                </Typography>
              </div>
            </Box>

            {callPathwayAnswers.map((callPathwayAnswer) => (
              <StyledGrid container direction="column" key={callPathwayAnswer.questionId}>
                <Typography variant="body2" mb={2}>
                  Q: {callPathwayAnswer.questionTitle}
                </Typography>
                <StyledTypography variant="body2">
                  {renderAnswerValue(callPathwayAnswer)}
                </StyledTypography>
              </StyledGrid>
            ))}
          </div>
        );
      })}

      {showCallSummary && renderIcdCodesAndTopicsDiscussed()}
    </StyledContainer>
  );
};

const StyledContainer = styled('div')(
  ({ theme }) => css`
    .generated-row {
      font-size: 14px;
      font-weight: 400;
      color: ${theme.palette.text.primary};
      display: inline-block;
    }
  `
);

const StyledGrid = styled(Grid)(
  ({ theme }) => css`
    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(8)};
    }
  `
);

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(2)};
    }
  `
);

export default observer(GeneratedCallDetailsView);
