import { ReactElement } from 'react';

import classNames from 'classnames';

import Icon from 'components/Icons/Icon';

import './ExpandableRow.scss';

interface IExpandableRowProps {
  isExpanded: boolean;
  onToggleExpand: () => void;
  title: string | JSX.Element;
  children: ReactElement;
}

const ExpandableRow = (props: IExpandableRowProps) => {
  return (
    <div className={classNames('expandable-row-view', { expand: props.isExpanded })}>
      <div className="expandable-row-header" onClick={props.onToggleExpand}>
        <span className="expand-icon">
          <Icon.Chevron right={!props.isExpanded} />
        </span>
        <div className="ml-2 title">{props.title}</div>
      </div>
      <div className="expandable-row-body">{props.children}</div>
    </div>
  );
};

export default ExpandableRow;
