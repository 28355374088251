// @ts-strict-ignore
// question data
import { isArray } from 'lodash/fp';

export enum OralQuestionType {
  medReceived = 'medReceived',
  firstPill = 'firstPill',
  regimenAsPrescribed = 'regimenAsPrescribed',
  changesInOtherMeds = 'changesInOtherMeds',
  missedDoses = 'missedDoses',
  pillsLeft = 'pillsLeft'
}
export enum OralQuestionStatus {
  NotAsked = 'notAsked',
  Answered = 'answered',
  IDontKnow = 'iDontKnow'
}

type OralAnswerValue = boolean | number | string | Date;

// structure data, represent oral data as sent by the server
export interface OralAnswerStructure {
  questionType: OralQuestionType;
  status: OralQuestionStatus;
  value?: OralAnswerValue;
  customMessage?: string;
  additions?: any;
}

export interface OralReportRegimenMeta {
  regimen: string;
  isCustom: false;
}

export interface OralReportResponse extends OralReportRegimenMeta {
  startOfCycle?: string;
  previousReportDate?: string;
  reportItems: OralAnswerStructure[];
}

// represent a single patient answer
export class ReportQuestionAnswer<T extends OralAnswerValue> implements OralAnswerStructure {
  private isOkValue?: boolean;
  questionType: OralQuestionType;
  status: OralQuestionStatus;
  value?: T;
  customMessage?: string;
  additions?: any;
  additionsParsed?: any;

  get isAnsweredOrDontKnow(): boolean {
    return this.isAnswered || this.isIDontKnow;
  }

  get isAnswered(): boolean {
    return this.status === OralQuestionStatus.Answered;
  }

  get isIDontKnow(): boolean {
    return this.status === OralQuestionStatus.IDontKnow;
  }

  // if true, means the patient answer fit the med regimen (no special attention required)
  get isOk(): boolean {
    return this.isAnswered && this.isOkValue !== undefined && this.value === this.isOkValue;
  }

  get callbackDenyReasonId(): number {
    // Mobile clients send the additions data differently, remove ambiguity after fixed on mobile clients
    return isArray(this.additions) ? this.additions[0] : this.additions;
  }

  constructor(answer: OralAnswerStructure, isOkValue?: any) {
    Object.assign(this, answer);
    this.isOkValue = isOkValue;
  }
}

// represent entire oral report
export class OralReport implements OralReportRegimenMeta {
  startOfCycle: Date;
  regimen: string;
  isCustom: false;
  firstPill?: ReportQuestionAnswer<Date>;
  medReceived?: ReportQuestionAnswer<boolean>;
  regimenAsPrescribed?: ReportQuestionAnswer<boolean>;
  changesInOtherMeds?: ReportQuestionAnswer<boolean>;
  missedDoses?: ReportQuestionAnswer<number>;
  pillsLeft?: ReportQuestionAnswer<number>;
  previousReportDate?: Date;

  get newCycleDate() {
    // if patient did not provide first pill date - use startOfCycle meta (can be update by a nurse)
    return this.firstPill?.isAnswered ? this.firstPill.value : this.startOfCycle;
  }

  set cycle(date: Date) {
    this.startOfCycle = date;
  }

  constructor(oralReportUnparsedResponse: OralReportResponse) {
    Object.assign(this, oralReportUnparsedResponse);
    this.startOfCycle = oralReportUnparsedResponse?.startOfCycle
      ? new Date(oralReportUnparsedResponse.startOfCycle)
      : null;
    this.previousReportDate = oralReportUnparsedResponse?.previousReportDate
      ? new Date(oralReportUnparsedResponse.previousReportDate)
      : null;

    oralReportUnparsedResponse.reportItems.forEach((answer) => {
      switch (answer.questionType) {
        case OralQuestionType.firstPill:
          answer.value = new Date(answer.value as string);
          this.firstPill = new ReportQuestionAnswer<Date>(answer);
          break;
        case OralQuestionType.medReceived:
          this.medReceived = new ReportQuestionAnswer<boolean>(answer, true);
          break;
        case OralQuestionType.regimenAsPrescribed:
          this.regimenAsPrescribed = new ReportQuestionAnswer<boolean>(answer, true);
          break;
        case OralQuestionType.changesInOtherMeds:
          this.changesInOtherMeds = new ReportQuestionAnswer<boolean>(answer, false);
          break;
        case OralQuestionType.missedDoses:
          this.missedDoses = new ReportQuestionAnswer<number>(answer, 0);
          break;
        case OralQuestionType.pillsLeft:
          this.pillsLeft = new ReportQuestionAnswer<number>(answer);
          break;
      }
    });
  }
}
