// @ts-strict-ignore
import { isArray } from 'lodash';
import { computed, observable, makeObservable } from 'mobx';

import { SymptomTicketUrgency } from 'models/OperatorTicket';
import { ReportedCause } from 'models/ReportedCause';

import { OralReport, ReportQuestionAnswer } from './QuestionnaireAnswerOral';

export enum ReportType {
  Distress = 'distress',
  Oral = 'oral',
  COVID = 'covid', // To be deprecated
  CallbackRequestTickets = 'callbackRequestTickets',
  DrugSpecific = 'drugSpecific'
}

export enum ReportSource {
  Mobile = 'App',
  Ivr = 'IVR',
  Web = 'WebApp'
}

export interface IReportAnswer {
  causes?: ReportedCause[];
  distressLevel?: number;
  oral?: OralReport;
  callbackRequest?: ReportQuestionAnswer<boolean>;
  // deprecated
  inputQuestions?: any;
  yesNoQuestions?: any;
}

export default class QuestionnaireAnswer {
  @observable id: number;

  @observable patientId: number;

  @observable type: ReportType;

  @observable answer: IReportAnswer;

  @observable createdAt: string;

  @observable patientReportIndex: number;

  @observable ticketId: number;

  @observable source: ReportSource;

  @observable urgency: SymptomTicketUrgency | null;

  isUnacknowledged = (lastReportAckTime: string) =>
    !lastReportAckTime || new Date(this.createdAt) > new Date(lastReportAckTime);

  isAfter = (date: Date) => new Date(this.createdAt) > date;

  constructor() {
    makeObservable(this);
  }

  // Used for SymptomReport wrapper in DistressReportsTable, remove if component was refactored
  get isOperatorReport() {
    return false;
  }

  get callbackDenyReasonId(): number {
    // Mobile clients send the additions data differently, remove ambiguity after fixed on mobile clients
    return isArray(this.answer.callbackRequest.additions)
      ? this.answer.callbackRequest.additions[0]
      : this.answer.callbackRequest.additions;
  }

  @computed
  get name() {
    switch (this.type) {
      case ReportType.Distress:
        return 'Symptom';
      case ReportType.Oral:
        return 'Oral Oncolytics';
      case ReportType.COVID:
        return 'COVID-19';
      case ReportType.DrugSpecific:
        return 'Drug Specific';
      default:
        throw new Error('Unknown Report Type');
    }
  }
}

export interface IReportedYesNoQuestion {
  id: number;
  questionText: string;
  value: boolean;
}

export interface IReportedInputQuestion {
  id: number;
  questionText: string;
  inputType: string;
  answer: any;
}
