import React, { FC } from 'react';

import { Dialogs } from 'analytics/events/dialog';

import { MessageDialog } from 'components/UIkit/atoms/Dialog';

interface Props {
  isOpen: boolean;
  onCancelClicked: () => void;
}

const SmsBlockedPopup: FC<Props> = ({ isOpen, onCancelClicked }) => (
  <MessageDialog
    id={Dialogs.UnsubscribeSms}
    title="This Number Has Blocked SMS from Canopy"
    isOpen={isOpen}
    handleClose={onCancelClicked}
    primaryActionProps={{ text: 'OK', onClick: onCancelClicked }}
  >
    This occurs when somebody has replied STOP to an SMS message from Canopy in the past.
    <br />
    <br />
    To un-block, this patient / Callback Contact must text YES to the same number they previously
    blocked.
  </MessageDialog>
);

export default SmsBlockedPopup;
