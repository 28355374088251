import { FunctionComponent, ReactNode, useEffect, useRef } from 'react';

interface Props {
  children: ReactNode;
  scrollDependencies: any[];
  className?: string;
}

const AutoScrollContainer: FunctionComponent<Props> = ({
  children,
  scrollDependencies,
  className
}) => {
  const autoScrollRef = useRef<HTMLDivElement>(null);

  useEffect(function scrollDownOnChanges() {
    if (autoScrollRef.current) {
      autoScrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line
  }, scrollDependencies);

  return (
    <div className={className}>
      {children}
      <div ref={autoScrollRef} />
    </div>
  );
};

export default AutoScrollContainer;
