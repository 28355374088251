// @ts-strict-ignore
import { FC, useState } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackOpenTextInputUsageAnalyticsEvent } from 'analytics/events/input-usage';

import { trackSectionUsageAnalyticsEvent } from 'analytics/events/section-usage';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { FEATURES } from 'constants/features';

import Patient from 'models/Patient';

import AutoScrollContainer from 'views/Widgets/AutoScrollContainer';
import ExpandableRow from 'views/Widgets/ExpandableRow';
import GeneratedCallDetailsView from 'views/Widgets/GeneratedCallDetailsView';

import './CallLoggingBody.scss';

interface CallLoggingBodyProps {
  patient: Patient;
  generateSmartSummaryPreference: boolean;
}

const Body: FC<CallLoggingBodyProps> = ({ patient, generateSmartSummaryPreference }) => {
  const { pathwaysStore, callLoggingStore, settingsStore } = useStores();
  const { currentCall } = callLoggingStore;
  const [isGeneratedDetailsSectionExpanded, setGeneratedDetailsSectionExpanded] = useState(false);
  const [isNotesSectionExpanded, setNotesSectionExpanded] = useState(true);
  const [myNotesValueOnFocus, setMyNotesValueOnFocus] = useState('');
  const allAnswers = pathwaysStore.orderedPathwayAnswers;

  const myNotesTextareaPlaceholder = `Start typing your notes here. Pathway responses are automatically inserted into the Canopy Generated section above. ${
    generateSmartSummaryPreference && settingsStore.hasFeature(FEATURES.CALL_LOGGER_SMART_SUMMARY)
      ? 'Smart Summary is enabled, and will automatically summarize Pathway responses into a readable format for provider review.'
      : ''
  }`;

  const handleNoteChanged = (note: string) => {
    callLoggingStore.updateCurrentCall({ note });
  };
  return (
    <div className="window-body">
      <AutoScrollContainer
        scrollDependencies={[pathwaysStore.orderedPathwayAnswers]}
        className="calls-list"
      >
        <ExpandableRow
          isExpanded={isGeneratedDetailsSectionExpanded}
          title={
            <div>
              <span>Canopy Generated</span>
              {allAnswers.length > 0 && (
                <span className="answers-counter-text">{` — ${allAnswers.length} Pathway Response${
                  allAnswers.length > 1 ? 's' : ''
                } Added`}</span>
              )}
            </div>
          }
          onToggleExpand={() => {
            trackSectionUsageAnalyticsEvent({
              action: isGeneratedDetailsSectionExpanded
                ? AnalyticEventAction.Collapse
                : AnalyticEventAction.Expand,
              value: 'Canopy Generated',
              virtual_page: 'call logger'
            });

            setGeneratedDetailsSectionExpanded(!isGeneratedDetailsSectionExpanded);
          }}
        >
          <GeneratedCallDetailsView
            call={null}
            patient={patient}
            primarySymptoms={currentCall.callReasons}
            pathwayAnswers={allAnswers}
          />
        </ExpandableRow>
        <ExpandableRow
          isExpanded={isNotesSectionExpanded}
          title="My Notes"
          onToggleExpand={() => {
            trackSectionUsageAnalyticsEvent({
              action: isNotesSectionExpanded
                ? AnalyticEventAction.Collapse
                : AnalyticEventAction.Expand,
              value: 'My Notes',
              virtual_page: 'call logger'
            });

            setNotesSectionExpanded(!isNotesSectionExpanded);
          }}
        >
          <textarea
            placeholder={myNotesTextareaPlaceholder}
            className="note-text-area"
            onFocus={() => {
              setMyNotesValueOnFocus(currentCall.note);
            }}
            onBlur={() => {
              trackOpenTextInputUsageAnalyticsEvent(
                currentCall.note,
                myNotesValueOnFocus,
                'My Note Section',
                { virtual_page: 'call logger' }
              );
            }}
            onChange={(e) => handleNoteChanged(e.target.value)}
            value={currentCall.note}
          />
        </ExpandableRow>
      </AutoScrollContainer>
    </div>
  );
};

export const CallLoggingBody = observer(Body);
