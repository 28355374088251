import { FC, ReactNode } from 'react';

import { Link as MuiLink } from '@mui/material';

import { Link } from 'react-router-dom';

import { StyledLink } from 'components/UIkit/atoms/Link/StyledLink';

import { getTypographyVariant } from 'components/UIkit/atoms/Link/utils';

interface Props {
  children?: ReactNode;
  size?: 'small' | 'medium' | 'large';
  variant?: 'primary' | 'secondary';
  to: string;
  isEllipsis?: boolean;
  newTabOnClick?: boolean;
}

//if "onClick" prop is needed - use the LinkButton component
export const InternalLink: FC<Props> = ({
  to,
  size = 'medium',
  variant = 'primary',
  newTabOnClick = false,
  isEllipsis = false,
  children
}) => (
  <StyledLink
    variant={getTypographyVariant(size)}
    color={variant}
    isEllipsis={isEllipsis}
    width={isEllipsis ? '100%' : 'initial'}
  >
    <MuiLink to={to} target={newTabOnClick ? '_blank' : ''} component={Link} underline="hover">
      {children}
    </MuiLink>
  </StyledLink>
);
