import { AnalyticEventAction, AnalyticEventName, AnalyticsService } from '../';

export enum Dialogs {
  ClearAllFields = 'ClearAllFields',
  DisconnectDraftAndResolve = 'DisconnectDraftAndResolve',
  DeleteTicketType = 'DeleteTicketType',
  DeleteTicketTypeSubSelect = 'DeleteTicketTypeSubSelect',
  DeleteTag = 'DeleteTag',
  ResolveConnectedTickets = 'ResolveConnectedTickets',
  DeactivateUser = 'DeactivateUser',
  DeleteAndSwitchProtocol = 'DeleteAndSwitchProtocol',
  RemoveCallbackContact = 'RemoveCallbackContact',
  DeleteTask = 'DeleteTask',
  DeleteTicket = 'DeleteTicket',
  RemoveReason = 'RemoveReason',
  PathwayUpdates = 'PathwayUpdates',
  DeleteFoodsToAvoid = 'DeleteFoodsToAvoid',
  UnsubscribeSms = 'UnsubscribeSms',
  HasActiveCmEpisode = 'HasActiveCmEpisode',
  HasOptOutOfCm = 'HasOptOutOfCm',
  MarkedAsIneligibleForCm = 'MarkedAsIneligibleForCm',
  CmEpisodeEnded = 'CmEpisodeEnded',
  DeleteEpisode = 'DeleteEpisode',
  DeleteRole = 'DeleteRole',
  DeleteTicketSubType = 'DeleteTicketSubType',
  DisableTicketCategory = 'DisableTicketCategory',
  DisplayNamesUpdate = 'DisplayNamesUpdate',
  CareTimerWillPause = 'CareTimerWillPause',
  CareTimerAutoPause = 'CareTimerAutoPause',
  DeleteCall = 'DeleteCall',
  DraftCallWasDeleted = 'DraftCallWasDeleted',
  ConfirmDuration = 'ConfirmDuration',
  MaximumDurationReached = 'MaximumDurationReached',
  AnotherCallTimerIsRunning = 'AnotherCallTimerIsRunning',
  DeleteItem = 'DeleteItem',
  TicketDeletedNotification = 'TicketDeletedNotification',
  SendBulkInvitationsViaSms = 'SendBulkInvitationsViaSms',
  ClearCmTableSelection = 'ClearCmTableSelection',
  DeleteTriageRule = 'DeleteTriageRule',
  RefreshDemo = 'RefreshDemo',
  CallLoggerSmartSummary = 'CallLoggerSmartSummary',
  ResolveAll = 'ResolveAll',
  RequestAllOverdueReports = 'RequestAllOverdueReports',
  PathwayEditedWarning = 'PathwayEditedWarning',
  GeneralError = 'GeneralError',
  InvalidPhoneOnProtocol = 'InvalidPhoneOnProtocol',
  PathwayBuilderJsonValidationError = 'PathwayBuilderJsonValidationError',
  PathwayNameAlreadyExists = 'PathwayNameAlreadyExists',
  RemovePathwayBlock = 'RemovePathwayBlock',
  RemovePathwayCategory = 'RemovePathwayCategory',
  EditPathwayQuestion = 'EditPathwayQuestion',
  DismissChangesPathwayQuestion = 'DismissChangesPathwayQuestion'
}

export const analyticsDialogIds: Record<Dialogs, string> = {
  [Dialogs.ClearAllFields]: 'Clear All Fields?',
  [Dialogs.DisconnectDraftAndResolve]: 'Disconnect Call Draft from Ticket & Resolve?',
  [Dialogs.DeleteTicketType]: 'Delete Ticket Type',
  [Dialogs.DeleteTicketTypeSubSelect]: 'Delete Ticket Type From Sub Dropdown',
  [Dialogs.DeleteTag]: 'Delete Tag',
  [Dialogs.ResolveConnectedTickets]: 'Resolve Connected Tickets?',
  [Dialogs.DeactivateUser]: 'Deactivate User',
  [Dialogs.DeleteAndSwitchProtocol]: 'Delete & Switch Protocols?',
  [Dialogs.RemoveCallbackContact]: 'Remove this callback contact?',
  [Dialogs.DeleteTask]: 'Delete Task',
  [Dialogs.DeleteTicket]: 'Delete this ticket?',
  [Dialogs.DeleteItem]: 'Delete this item?',
  [Dialogs.RemoveReason]: 'Remove this reason from suggestions?',
  [Dialogs.PathwayUpdates]: 'Pathway Updates',
  [Dialogs.DeleteFoodsToAvoid]: 'Delete Foods to Avoid Item',
  [Dialogs.UnsubscribeSms]: 'Unsubscribed from SMS Messages',
  [Dialogs.HasActiveCmEpisode]: 'Patient has Active Care Management Episode',
  [Dialogs.HasOptOutOfCm]: 'Patient has Opted Out of Care Management',
  [Dialogs.MarkedAsIneligibleForCm]: 'Patient is Marked as Ineligible for Care Management',
  [Dialogs.CmEpisodeEnded]: 'Patient Was in a Care Management Episode That Was Ended',
  [Dialogs.DeleteEpisode]: 'Delete Episode',
  [Dialogs.DeleteRole]: 'Delete this role?',
  [Dialogs.DeleteTicketSubType]: 'Delete Ticket Sub Type',
  [Dialogs.DisableTicketCategory]: 'Disable Ticket Category for Operators and Patients?',
  [Dialogs.DisplayNamesUpdate]: 'Display Name(s) for Patient App Will Be Updated',
  [Dialogs.CareTimerWillPause]: 'Care Timer Will Pause',
  [Dialogs.CareTimerAutoPause]: 'Care Timer Auto-Paused',
  [Dialogs.DeleteCall]: 'Delete Call',
  [Dialogs.DraftCallWasDeleted]:
    'This call has been deleted, but you can still save it as a new call',
  [Dialogs.ConfirmDuration]: 'Please Confirm Duration',
  [Dialogs.MaximumDurationReached]: 'Maximum Duration Reached',
  [Dialogs.AnotherCallTimerIsRunning]: 'Another Call Timer is Running',
  [Dialogs.TicketDeletedNotification]: 'Notification Leads to Deleted Ticket',
  [Dialogs.SendBulkInvitationsViaSms]: 'Send Bulk Invitation Via SMS',
  [Dialogs.ClearCmTableSelection]: 'Clear Selection?',
  [Dialogs.DeleteTriageRule]: 'Delete Triage Rule?',
  [Dialogs.RefreshDemo]: 'Are you sure?',
  [Dialogs.CallLoggerSmartSummary]: 'Introducing: Smart Summary',
  [Dialogs.ResolveAll]: 'Resolve Patient Reports',
  [Dialogs.RequestAllOverdueReports]: 'Request Overdue Reports',
  [Dialogs.PathwayEditedWarning]: 'Pathway Edited — Consider Regenerating Smart Summary',
  [Dialogs.GeneralError]: 'Error',
  [Dialogs.InvalidPhoneOnProtocol]: 'Phone Number Invalid',
  [Dialogs.PathwayBuilderJsonValidationError]: 'JSON Validation Error',
  [Dialogs.PathwayNameAlreadyExists]: 'Pathway Name Already Exists',
  [Dialogs.RemovePathwayBlock]: 'Remove from Pathway',
  [Dialogs.RemovePathwayCategory]: 'Remove Category and All Questions',
  [Dialogs.EditPathwayQuestion]: 'Edit Pathway Question',
  [Dialogs.DismissChangesPathwayQuestion]: 'Dismiss Changes to Pathway Question'
};

export function getAnalyticsId(id: Dialogs): string {
  const analyticsId = analyticsDialogIds[id];
  if (!analyticsId) {
    throw new Error(`Dialog '${id}' is not listed in 'analyticsDialogIds'`);
  }
  return analyticsId;
}

type Action = string | AnalyticEventAction.Cancel | AnalyticEventAction.CancelIcon;

interface AnalyticsEvent {
  action: Action;
  value: string;
}

export const trackDialogAnalyticsEvent = (action: AnalyticEventAction | string, id?: Dialogs) => {
  const analyticsId = id ? getAnalyticsId(id) : '';
  if (analyticsId && action) {
    AnalyticsService.trackEvent<AnalyticsEvent>({
      name: AnalyticEventName.Popup,
      properties: { action, value: analyticsId }
    });
  }
};
