import { SymptomTicketUrgency, OperatorTicketUrgency } from 'models/OperatorTicket';

import { IReportAnswer, ReportType } from 'models/QuestionnaireAnswer';
import { CauseSeverity } from 'models/ReportedCause';

export enum ReportTableCellContentIcons {
  pill,
  question
}

export enum ReportTableCellDataColor {
  gray = 0,
  green = CauseSeverity.Mild,
  orange = 2,
  red = 3,
  darkRed = CauseSeverity.Unbearable,
  purple = 5
}

export enum ReportTableIcon {
  phone,
  pill,
  question,
  check,
  x,
  phoneCall
}

export interface ReportTableCellData {
  color: ReportTableCellDataColor;
  content?: number | string;
  iconContent?: ReportTableIcon;
  tooltip?: string;
}

export interface CallsAndCallbackPreferenceTableCellData {
  color: ReportTableCellDataColor;
  iconContent: ReportTableIcon;
  callbackPreference: string;
  calls: string[];
  drafts: string[];
}

export type ReportTableRowData = Array<ReportTableCellData | null>;
export type CallsAndCallbackPreferenceTableRowData = Array<CallsAndCallbackPreferenceTableCellData>;

export interface ReportTableHeaderData {
  text: string;
  isOperator: boolean;
}

export type ReportTableOralChemoSection = {
  NewCycle: ReportTableRowData;
  MedReceived: ReportTableRowData;
  RegimenAdherence: ReportTableRowData;
  ChangeInOtherMeds: ReportTableRowData;
  MissedDose: ReportTableRowData;
};

export type ReportTableSymptomSection = {
  [key: string]: ReportTableRowData;
};

export interface ReportTableData {
  headerRow: ReportTableHeaderData[];
  distressRow: ReportTableRowData;
  oralChemoSection: ReportTableOralChemoSection;
  symptomsSection: ReportTableSymptomSection;
  numericValuesSection: ReportTableSymptomSection;
  callbackRow: CallsAndCallbackPreferenceTableRowData;
}

export const StartOfCycle = 'startOfCycle';
export interface TableReportItem {
  type: ReportType | typeof StartOfCycle;
  createdAt: Date;
  isOperatorReport: boolean;
  operatorReportUrgency?: OperatorTicketUrgency | SymptomTicketUrgency;
  answer?: IReportAnswer;
  callIds: number[];
}
